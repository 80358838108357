export type InnerDataValue = {
  telemetry: number;
  unit: string;
  limit: LimitProp;
  fname: string;
  mname: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export interface Dbdata{
  telemetry: number;
  unit: string;
  //limit: LimitProp;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      className: string;
      id: string;
    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export type Field = {
  name: string;
  type: string;
  labels?: { [key: string]: string };
  values: any[]; // Replace 'any' with a more specific type if possibl
};

export interface ProcessedData {
  [key: string]: { cnvValue: string, mne: string, name: string }[];
}

export interface DataObject {
  name: string;
  refId: string;
  length: number;
  fields: Field[];
  labels: { [key: string]: string };
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value === mne) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export const nameToMne = {

  "He Bottle 1 Temp":"tcs_supervisor_he_bottle_1_t", /*  */
  "He Bottle 2 Temp":"tcs_supervisor_he_bottle_2_t", /*  */

  "He PV2 Pyro En":"eps_piu_dio_pv2_pyro_he_en", /* PV2 HP NC */
  "He PV2 Pyro Status":"eps_piu_dio_pv2_pyro_he_status", /*  */

  "He Reg Temp":"tcs_spoc_he_reg_t", /* Reg1 */
  "He Reg Out En":"eps_piu_dio_pt_he_reg_out_en", /*  */
  "He Reg Out Pwr":"eps_piu_power_cl_pt_he_reg_out_p", /*  */

  "LV1 Ox Curr":"eps_piu_power_rail_lv1_ox_curr_sns", /* LV1 LP */
  "LV1 Ox P":"eps_piu_power_rail_lv1_ox_p_tlm", /*  */

  "LV2 Fu Curr":"eps_piu_power_rail_lv2_fu_curr_sns", /* LV2 LP */
  "LV2 Fu P":"eps_piu_power_rail_lv2_fu_p_tlm", /*  */

  "Ox Fh 1 Temp":"tcs_spoc_fh1_ox_t", /*  */
  "Fu Fh 2 Temp":"tcs_spoc_fh2_fu_t", /*  */

  "Ox Pyro En":"eps_piu_dio_pv5_pyro_ox_en", /* PV5 LP */
  "Ox Pyro Status":"eps_piu_dio_pv5_pyro_ox_status", /*  */
  "Ox Pyro Volt":"eps_piu_power_rail_pv5_pyro_ox_v", /*  */

  "Fu Pyro En":"eps_piu_dio_pv6_pyro_fu_en", /* PV6 LP NC */
  "Fu Pyro Status":"eps_piu_dio_pv6_pyro_fu_status", /*  */
  "Fu Pyro Volt":"eps_piu_power_rail_pv6_pyro_fu_v", /*  */

  "MOV Pri Cycle Cnt":"prop_ariane_s_400_engine_cycle_count_mov_pri", /* MOV Number of times MOV (Primary Coil) was actuated */
  "MOV Sec Cycle Cnt":"prop_ariane_s_400_engine_cycle_count_mov_sec", /* Number of times MOV (Secondary Coil) was actuated */
  "MOV Pri Valve State":"prop_ariane_s_400_engine_valve_state_mov_pri", /* Current state of the MOV (Primary Coil) */
  "MOV Sec Valve State":"prop_ariane_s_400_engine_valve_state_mov_sec", /* Current state of the MOV (Secondary Coil) */

  "MFV Pri Cycle Cnt":"prop_ariane_s_400_engine_cycle_count_mfv_pri", /* MFV Number of times MFV (Primary Coil) was actuated */
  "MFV Sec Cycle Cnt":"prop_ariane_s_400_engine_cycle_count_mfv_sec", /* Number of times MFV (Secondary Coil) was actuated */
  "MFV Pri Valve State":"prop_ariane_s_400_engine_valve_state_mfv_pri", /* Current state of the MFV (Primary Coil) */
  "MFV Sec Valve State":"prop_ariane_s_400_engine_valve_state_mfv_sec", /* Current state of the MFV (Secondary Coil) */

  "TVCX En":"eps_piu_dio_tvc_x_en", /* TVC X */
  "TVCX Poweri":"eps_piu_power_rail_tvc_x_i", /*  */
  "TVCX Op Mode":"prop_tvc_x_operating_mode", /* Servo control mode (current velocity position (default) etc) */

  "TVCZ En":"eps_piu_dio_tvc_z_en", /* TVC Z */
  "TVCZ Poweri":"eps_piu_power_rail_tvc_z_i", /*  */
  "TVCZ Op Mode":"prop_tvc_z_operating_mode", /* Servo control mosde (crrent velocity position (default) etc) */
  
  "N2 Htr En":"eps_spoc_dio_htr_n2_bottles_en", /*  */
  "N2 T1":"tcs_spoc_n2_bottle_1_t", /*  */
  "N2 T2":"tcs_spoc_n2_bottle_2_t", /*  */
  "N2 T3":"tcs_spoc_n2_bottle_3_t", /*  */
  "N2 T4":"tcs_spoc_n2_bottle_4_t", /*  */
  "N2 TP1":"tcs_spoc_rtd_n2_bottle_probe_1_t", /*  */
  "N2 TP2":"tcs_spoc_rtd_n2_bottle_probe_2_t", /*  */
  "N2 TP3":"tcs_spoc_rtd_n2_bottle_probe_3_t", /*  */
  "N2 TP4":"tcs_spoc_rtd_n2_bottle_probe_4_t", /*  */
  "RCSA T":"tcs_spoc_rcs_a_t", /*  */
  "RCSB T":"tcs_spoc_rcs_b_t", /*  */

  "Gn2 Bottle PT En":"eps_spoc_dio_cl_pt_gn2_bottle_en", /*  */
  "Gn2 Bottle PT I":"eps_spoc_power_cl_pt_gn2_bottle_i", /*  */
  "Gn2 Bottle PT P":"eps_spoc_power_cl_pt_gn2_bottle_p_agg", /*  */
  "Gn2 Regout PT En":"eps_spoc_dio_cl_pt_gn2_regout_en", /*  */
  "Gn2 Regout PT I":"eps_spoc_power_cl_pt_gn2_regout_i", /*  */
  "Gn2 Regout PT P":"eps_spoc_power_cl_pt_gn2_regout_p", /*  */

  "Ox Act Count":"prop_ariane_s_400_engine_cycle_count_lv_1_ox", /* Number of times LV1 (Oxygen) was actuated */
  "Fu Act Count":"prop_ariane_s_400_engine_cycle_count_lv_2_fu", /*  */

  "Ox Line Pt":"eps_piu_dio_pt_ox_line_en", /* PT7 LP */
  "Ox Tank 1 Pt":"eps_piu_dio_pt_ox_tank_1_en", /* PT3 LP */
  "Ox Tank 2 Pt":"eps_piu_dio_pt_ox_tank_2_en", /* PT5 LP */

  "Fu Line Pt":"eps_piu_dio_pt_fu_line_en", /* PT8 LP */
  "Fu Tank 1 Pt":"eps_piu_dio_pt_fu_tank_1_en", /* PT4 LP */
  "Fu Tank 2 Pt":"eps_piu_dio_pt_fu_tank_2_en", /* PT6 LP */

  "Fu Line P":"eps_piu_power_cl_pt_fu_line_p", /*  */
  "Fu Tank 1 P":"eps_piu_power_cl_pt_fu_tank_1_p", /*  */
  "Fu Tank 2 P":"eps_piu_power_cl_pt_fu_tank_2_p", /*  */
  "He Bottle PT En":"eps_piu_dio_pt_he_bottles_en", /* Pressure Transducer PT2 LP*/
  "He Bottle PT P":"eps_piu_power_cl_pt_he_bottles_p", /*  */
  "Ox Line P":"eps_piu_power_cl_pt_ox_line_p", /*  */
  "Ox Tank 1 P":"eps_piu_power_cl_pt_ox_tank_1_p", /*  */
  "Ox Tank 2 P":"eps_piu_power_cl_pt_ox_tank_2_p", /*  */

  "Fu Line I":"eps_piu_power_cl_pt_fu_line", /*  */
  "Fu Tank 1 I":"eps_piu_power_cl_pt_fu_tank_1", /*  */
  "Fu Tank 2 I":"eps_piu_power_cl_pt_fu_tank_2", /*  */
  "He Bottle I":"eps_piu_power_cl_pt_he_bottles", /*  */
  "He Reg Out I":"eps_piu_power_cl_pt_he_reg_out", /*  */
  "Ox Line I":"eps_piu_power_cl_pt_ox_line", /*  */
  "Ox Tank 1 I":"eps_piu_power_cl_pt_ox_tank_1", /*  */
  "Ox Tank 2 I":"eps_piu_power_cl_pt_ox_tank_2", /*  */

  "He Pyro Volt":"eps_piu_power_rail_pv2_pyro_he_v", /*  */

  "Burn Seq":"prop_ariane_s_400_engine_main_thruster_burn_sequence_state", /* */
  "Spoc A Volt":"eps_supervisor_power_rail_spoca_vtlm", /* */
  "Spoc B Volt":"eps_supervisor_power_rail_spocb_vtlm", /* */

  "Piu Cmd Err Ct":"cdh_piu_command_error_count", /* */
  "Piu Cmd Succ Ct":"cdh_piu_command_success_count", /* */
  "Piu Cl Pwr En":"eps_piu_dio_cl_pwr_en", /* */
  "Piu Lv Adc En":"eps_piu_dio_lv_adc_en", /* */
  "Piu Lv Adc Tlm En":"eps_piu_dio_lv_adc_tlm_en", /* */
  "Piu Main Pwr En":"eps_spoc_dio_piu_main_pwr_en", /* */
  "Piu Pwr I":"eps_spoc_power_piu_i", /* */
  "Piu T":"tcs_spoc_piu_t", /* */

  "RWA Pwr En":"eps_spoc_dio_rw_a_pwr_en", /* */
  "RWA Curr":"eps_spoc_power_rw_a_i", /* */
  "RWA Volt":"eps_spoc_power_rw_a_v", /* */
  "RWA HW Speed":"aocs_rw_a_hw_speed", /* Reaction wheel speed */
  "RWA HW Temp":"aocs_rw_a_hw_temp0", /* Motor winding temperature */
  "RWB Pwr En":"eps_spoc_dio_rw_b_pwr_en", /* */
  "RWB Curr":"eps_spoc_power_rw_b_i", /* */
  "RWB Volt":"eps_spoc_power_rw_b_v", /* */
  "RWB HW Speed":"aocs_rw_b_hw_speed", /* Reaction wheel speed */
  "RWB HW Temp":"aocs_rw_b_hw_temp0", /* Motor winding temperature */
  "RWC Pwr En":"eps_spoc_dio_rw_c_pwr_en", /* */
  "RWC Curr":"eps_spoc_power_rw_c_i", /* */
  "RWC Volt":"eps_spoc_power_rw_c_v", /* */
  "RWC HW Speed":"aocs_rw_c_hw_speed", /* Reaction wheel speed */
  "RWC HW Temp":"aocs_rw_c_hw_temp0", /* Motor winding temperature */
  "RWD Pwr En":"eps_spoc_dio_rw_d_pwr_en", /* */
  "RWD Curr":"eps_spoc_power_rw_d_i", /* */
  "RWD Volt":"eps_spoc_power_rw_d_v", /* */
  "RWD HW Speed":"aocs_rw_d_hw_speed", /* Reaction wheel speed */
  "RWD HW Temp":"aocs_rw_d_hw_temp0", /* Motor winding temperature */

  "Fu Pri En":"eps_piu_dio_eng_fu_pri_en", /* */
  "Fu Sec En":"eps_piu_dio_eng_fu_sec_en", /* */
  "Fu Pri I":"eps_piu_power_valve_eng_fu_pri_i", /* */
  "Fu Sec I":"eps_piu_power_valve_eng_fu_sec_i", /* */
  "Fu T":"tcs_spoc_eng_fu_t", /* */
  "Ox Pri En":"eps_piu_dio_eng_ox_pri_en", /* */
  "Ox Sec En":"eps_piu_dio_eng_ox_sec_en", /* */
  "Ox Pri I":"eps_piu_power_valve_eng_ox_pri_i", /* */
  "Ox Sec I":"eps_piu_power_valve_eng_ox_sec_i", /* */
  "Ox T":"tcs_spoc_eng_ox_t", /* */

  "Fu T1 NHtr En":"eps_spoc_dio_htr_fu_tank_n_1_en", /* */
  "Fu T2 NHtr En":"eps_spoc_dio_htr_fu_tank_n_2_en", /* */
  "Fu T1 SHtr En":"eps_spoc_dio_htr_fu_tank_s_1_en", /* */
  "Fu T2 SHtr En":"eps_spoc_dio_htr_fu_tank_s_2_en", /* */
  "Ox T1 NHtr En":"eps_spoc_dio_htr_ox_tank_n_1_en", /* */
  "Ox T2 NHtr En":"eps_spoc_dio_htr_ox_tank_n_2_en", /* */
  "Ox T1 SHtr En":"eps_spoc_dio_htr_ox_tank_s_1_en", /* */
  "Ox T2 SHtr En":"eps_spoc_dio_htr_ox_tank_s_2_en", /* */

  "N2 T1 NHtr En":"eps_spoc_dio_htr_tank_n_1_en", /* */
  "N2 T1 SHtr En":"eps_spoc_dio_htr_tank_s_1_en", /* */
  "N2 T2 NHtr En":"eps_spoc_dio_htr_tank_n_2_en", /* */
  "N2 T2 SHtr En":"eps_spoc_dio_htr_tank_s_2_en", /* */
  "N2 T3 NHtr En":"eps_spoc_dio_htr_tank_n_3_en", /* */
  "N2 T3 SHtr En":"eps_spoc_dio_htr_tank_s_4_en", /* */
  "N2 T4 NHtr En":"eps_spoc_dio_htr_tank_n_4_en", /* */
  "N2 T4 SHtr En":"eps_spoc_dio_htr_tank_s_4_en", /* */

  "RCSA Htr En":"eps_spoc_dio_htr_rcs_a_en_agg", /* */
  "RCSB Htr En":"eps_spoc_dio_htr_rcs_a_en_agg", /* */
  
  "HeB1 Htr En":"eps_supervisor_dio_htr_he_bottle_1_en", /* */
  "HeB2 Htr En":"eps_supervisor_dio_htr_he_bottle_2_en", /* */

  "Vehicle Mode Agg":"state_spinand0_last_cmd_vehicle_mode_agg",

  "Ox Ini Mass":"prop_ariane_s_400_engine_ox_initial_mass_agg_7672", /* */
  "Ox Rem Mass":"prop_ariane_s_400_engine_ox_mass_remaining_agg_7666", /* */
  "Fu Ini Mass":"prop_ariane_s_400_engine_fu_initial_mass_agg_7672", /* */
  "Fu Rem Mass":"prop_ariane_s_400_engine_fu_mass_remaining_agg_7666", /* */
}

export function determineColor(value: number, limit: LimitProp){
  if (limit.rl !== null && value < limit.rl){
    return 'red-limit';
  }else if (limit.rh !== null && value > limit.rh){
    return 'red-limit';
  }else if (limit.rl !== null && limit.yl !== null && value >= limit.rl && value < limit.yl) {
    return 'yellow-limit';
  }else if (limit.yh !== null && limit.rh !== null && value >= limit.yh && value < limit.rh) {
    return 'yellow-limit';
  }else if (limit.yl !== null && limit.yh !== null && value >= limit.yl && value < limit.yh) {
    return 'green-limit';
  }else if (limit.rl !== null && limit.rh !== null && value >= limit.rl && value < limit.rh){
    return 'green-limit';
  }else{
    return 'gray-limit';
  }
}

export function determineColorNew(name: string){

if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
  return 'red-limit';
}
else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
    return 'yellow-limit';
}else{
  return 'gray-limit';
}
}
