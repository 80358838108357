import React from 'react'
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';

const RegBox: React.FC = () => {

  const regContainer = css`
  width: 400px;
  height: 500px;
  border: 6px dashed rgb(147, 151, 159);
`;

  return (
    <div className={regContainer}>

      <Handle type="target" position={Position.Bottom} id="target-bottom" />
      <Handle type="source" position={Position.Top} id="source-top" />
      <Handle type="source" position={Position.Right} id="source-right" />
      <Handle type="source" position={Position.Bottom} id="source-bottom" />

    </div>
  )
}

export default RegBox;
