import React from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {
    
    const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeColor = data && data.pwr.telemetry === "On" ? 'green' : 'white';


  const edgeStyle = {
    ...style,
    stroke: edgeColor,
  }


  let finaledge;

  //const edge1 = `M480 575.5L 510,575.5Q 520,575.5 520,565.5L 520,300Q 520,290 530,290L 2365.5,290Q 2375.5,290 2375.5,300L2375.5 571`;
  if (id === 'edge2'){
    const edge = `M${sourceX} ${sourceY}L${sourceX+130} ${sourceY}`;
    finaledge = edge;
  }
  else if ( id === 'edge17'){
    const edge = `M${sourceX} ${sourceY}L${sourceX+110} ${sourceY}`;
    finaledge = edge;
  }
  else if (id === 'edge16'){
    const edge = `M${sourceX} ${sourceY} L${sourceX-110} ${sourceY}`;
    finaledge = edge;
  }
  else if (id === 'PT5OXT2' || id === 'PT6OXT2'){
    const edge = `M${sourceX} ${sourceY} L${sourceX-25} ${sourceY}`;
    finaledge = edge;
  }
  else if (id === 'PT3OXT1' || id === 'PT4OXT1'){
    const edge = `M${sourceX} ${sourceY} L${sourceX+25} ${sourceY}`;
    finaledge = edge;
  }
  else if (id === 'edge21' || id === 'edge22'){
    const edge = `M${sourceX-80} ${sourceY} L${sourceX+275} ${sourceY}`;
    finaledge = edge;
  }
  else if (id === 'edge41' || id === 'edge42' || id === 'edge43' || id === 'edge44'){
    const edge = `M${sourceX} ${sourceY} L${sourceX} ${sourceY+80}`;
    finaledge = edge;
  }
  else if (id === 'edge45' || id === 'edge46' || id === 'edge47' || id === 'edge48'){
    const edge = `M${sourceX} ${sourceY} L${sourceX} ${sourceY-100}`;
    finaledge = edge;
  }
  else if (id === 'edge49'){
    const edge = `M${sourceX} ${sourceY} L${sourceX} ${sourceY-50}`;
    finaledge = edge;
  }
  else if (id === 'edge53'){
    sourceY = sourceY + 30;
    targetX = targetX + 20;
    const edge = `M${sourceX} ${sourceY} L${targetX-5} ${sourceY} Q ${targetX},${sourceY} ${targetX},${sourceY+5} L${targetX} ${targetY-5} Q ${targetX},${targetY} ${targetX-5},${targetY} L${targetX-80} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge54'){
    sourceY = sourceY + 10;
    targetX = targetX + 40;
    const edge = `M${sourceX} ${sourceY} L${targetX-5} ${sourceY} Q ${targetX},${sourceY} ${targetX},${sourceY+5} L${targetX} ${targetY-5} Q ${targetX},${targetY} ${targetX-5},${targetY} L${targetX-100} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge55'){
    sourceY = sourceY - 10;
    targetX = targetX + 60;
    const edge = `M${sourceX} ${sourceY} L${targetX-5} ${sourceY} Q ${targetX},${sourceY} ${targetX},${sourceY+5} L${targetX} ${targetY-5} Q ${targetX},${targetY} ${targetX-5},${targetY} L${targetX-120} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge56'){
    sourceY = sourceY - 30; 
    targetX = targetX + 80; 
    const edge = `M${sourceX} ${sourceY} L${targetX-5} ${sourceY} Q ${targetX},${sourceY} ${targetX},${sourceY+5} L${targetX} ${targetY-5} Q ${targetX},${targetY} ${targetX-5},${targetY} L${targetX-140} ${targetY}`;
    finaledge = edge;
  }
  else if (id === 'edge59'){
    const edge = `M${sourceX} ${sourceY} L${sourceX+90} ${sourceY}`;
    finaledge = edge;
  }
  else if (id === 'edge60'){
    const edge = `M${sourceX} ${sourceY} L${sourceX-90} ${sourceY}`;
    finaledge = edge;
  }
  else{
    finaledge = edgePath;
  }

  return (
    <>
      <BaseEdge path={finaledge} markerStart={markerStart} markerEnd={markerEnd} style={edgeStyle}/>
      <EdgeLabelRenderer>
        <div style={{
            position: 'absolute',
            transform: `translate(-50%, -100%) translate(${labelX}px,${labelY}px)`,
          }}>
        </div>
      </EdgeLabelRenderer>
    </>
  );

}
