import React from 'react'
import { css } from '@emotion/css';
import { Handle, Position } from 'reactflow';

interface PanelProp {
  data: {
    source: string;
  };
}

const Panel: React.FC<PanelProp> = ({data}) => {

  let color;

  if (data.source.includes('escg')) {
    color = 'gold';
  }else if (data.source.includes('escb')) {
    color = 'rgb(13, 57, 152)';
  }else{
    color = 'gray';
  }

  const panelStyle = css`
    width: 1975px;
    height: 2750px;
    border: 6px solid ${color};
    background-color: rgb(38, 38, 38);
  `;

  return (
    <div className={panelStyle}>

      <p>Propulsion System</p>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:90}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>
    </div>
  )
}

export default Panel;
