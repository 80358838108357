import { css } from '@emotion/css';
export const mfvContainer = css` 
display: flex;
flex-direction: column-reverse;
align-items: start;
`;

export const movContainer = css`
display: flex;
flex-direction: column-reverse;
align-items: end;
`;


// Define a type for the class key
export type ClassKey = 'movContainer' | 'mfvContainer' ;