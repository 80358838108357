import { css } from '@emotion/css';
export const het1Container = css`
display: flex;
flex-direction: row-reverse;
width: 480px;
justify-content: space-between;
align-items: center;

`;

export const het2Container = css`
display: flex;
width: 480px;
justify-content: space-between;
align-items: center;
`;


// Define a type for the class key
export type ClassKey = 'het1Container' | 'het2Container' ;