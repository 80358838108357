import React from 'react'
import { css } from '@emotion/css';

const EngAssy: React.FC = () => {

  const EngAssyContainer = css`
  width: 1900px;
  height: 900px;
  border: 6px dashed rgb(147, 151, 159);
`;


  return (
    <div className={EngAssyContainer}>
      <p>Engine Assy</p>
    </div>
  )
}

export default EngAssy;
