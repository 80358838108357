import React from 'react'
import { Handle, Position } from 'reactflow';
import { css } from '@emotion/css';

interface OxBoxProp {
  data: {
    source: string;
  };
}

const OxBox: React.FC<OxBoxProp> = ({data}) => {


  //border: 6px solid rgb(13, 57, 152);

  const oxBox = css`
  width: 800px;
  height: 1225px;
  `;

  return (
    <div className={oxBox}>

      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

    </div>
  )
}

export default OxBox;
