import { css } from '@emotion/css';

export const BimBox = css`
width: 770px;

& > div {

  & > div {
    width: 380px;

    & div:nth-of-type(2) {
      width: 180px;
    }
  }
}
`;

export const piuBox = css`
width: 1750px;

& > div {

  & > div {
    width: 470px;

    & div:nth-of-type(2) {
      width: 160px;
    }
  }

  & > div:nth-of-type(3), & > div:nth-of-type(7), & > div:nth-of-type(4), & > div:nth-of-type(8) {
    width: 400px;

    & div:nth-of-type(2) {
      width: 160px;
    }
  }

}
`;

// Define a type for the class keys
export type ClassKey = 'piuBox';
