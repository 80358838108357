import React from 'react'
import { css } from '@emotion/css';

const ManifoldC: React.FC = () => {

  const manCContainer = css`
  width: 375px;
  height: 275px;
  border: 6px dashed rgb(147, 151, 159);
`;

  return (
    <div className={manCContainer}>

    </div>
  )
}

export default ManifoldC;
