import { css } from '@emotion/css';
export const fh1Container = css` 
display: flex;
flex-direction: row-reverse;
justify-content: space-between;
align-items: center;
width: 350px;
`;

export const fh2Container = css`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 350px;
`;


// Define a type for the class key
export type ClassKey = 'fh1Container' | 'fh2Container' ;