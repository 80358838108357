import React from 'react'
import { css } from '@emotion/css';

const ManifoldD: React.FC = () => {

  const ManifoldDContainer = css`
  width: 300px;
  height: 275px;
  border: 6px dashed rgb(147, 151, 159);
  `;

  return (
    <div className={ManifoldDContainer}>

    </div>
  )
}

export default ManifoldD;
