import React, { useState } from 'react';
import {InnerData, determineColorNew} from './utils';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import copy from 'copy-to-clipboard';

interface LVProp{
  data:{
    name: string;
    value: InnerData;
    className: string;
  }
}

type VisibleTooltips = { [key: string]: boolean };

const LV: React.FC<LVProp> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  return (
    <div >

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{opacity:0, top:50, left:50}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:50, right:50}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>


        <div>

        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="161px" height="101px" viewBox="-0.5 -0.5 201 111"><defs/>
          <g>
            <g>
              <rect x="70" y="40" width="120" height="70" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeDasharray="8 8" pointerEvents="all"/></g><g>
                <rect x="0" y="0" width="160" height="80" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" pointerEvents="all"/>
          </g>
          <g>
            <rect x="95" y="15" width="50" height="50" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeDasharray="8 8" pointerEvents="all"/>
            </g>
            <g>
              <path d="M 115 79.5 L 105 79.5 L 105 19.5 L 94.5 19.5 L 110 0.5 L 125.5 19.5 L 115 19.5 Z" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
              </g>
            </g>
          </svg>
              
        </div>

        <div className={scheme.svgValueColCon}>
      {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData?.live}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
        
        </div>
    </div>
  );
}

export default LV;
