import React, {useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { Field, ProcessedData, nameToMne } from './utils';
import CustomEdge from './CustomEdge';
import Panel from 'module/Panel';
import PT from './PT';
import PV from './PV';
import Reg from './Reg';
import LV from './LV';
import Hose from './Hose';
import TVCZ from './TVCZ';
import TVCX from './TVCX';
import FluidEdge2 from './FluidEdge2';
import EngAssy from 'module/EngAssy';
import PMDTank from './PMDTank';
import RCSA from 'module/RCSA';
import ManifoldC from 'module/ManifoldC';
import ManifoldD from 'module/ManifoldD';
import SV from './SV';
import Tank from './Tank';
import FilterH from './FilterH';
import FilterV from './FilterV';
import Thruster from './Thruster';
import RegBox from 'module/RegBox';
import RCSReg from './RCSReg';
import RCSPanel from 'module/RCSPanel';
import OxBox from 'module/TankBox';
import { css } from '@emotion/css';
import MValve from './MValve';
import Temp from './Temp';
import PropThruster from './PropThruster';
import RCSThruster from './RCSThruster';
import Spoc from './SPOC';
import Textbox from './TextBox';
import HTextbox from './HTextbox';
import RW from './RW';
import Heater from './Heater';
import ValueBox from './ValueBox';
import { useTimeRangeContext } from './TimeRangeContext';
import VModeBox from './VModeBox';

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limit
  telemetry: string; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type f unit
  live: string;
  mne: string;
  spacecraft: string;
};

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  options: {
    text: string;                  // Corresponds to the 'addTextInput' option
    showSeriesCount: boolean;      // Corresponds to the 'addBooleanSwitch' option
    seriesCountSize: 'sm' | 'md' | 'lg';  // Corresponds to the 'addRadio' option
    X: number;           // Corresponds to the 'addNumberInput' option
    Y: number;
    Zoom: number;
  };
  influxData: ProcessedData;
  // ... other props if there are any
};

const nodeTypes = {rcsThruster:RCSThruster,valuebox:ValueBox,heater:Heater,rw:RW,vmodeBox:VModeBox,textbox:Textbox,htextbox:HTextbox,spoc:Spoc,propThruster:PropThruster, temp:Temp,mValve:MValve,oxBox:OxBox, panel:Panel,engAssy:EngAssy,pmdTank:PMDTank, pt:PT,pv:PV, reg:Reg, lv:LV,hose:Hose, tvcx:TVCX, tvcz:TVCZ, rcsa:RCSA, manifoldC:ManifoldC,manifoldD:ManifoldD,regbox:RegBox,rcsReg:RCSReg,sv:SV,tank:Tank,filterH:FilterH, filterV:FilterV,thruster:Thruster, rcsPanel: RCSPanel}
const edgeTypes = {customEdge: CustomEdge, fluidedge2: FluidEdge2}

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-panel, .react-flow__node-rcsPanel{
  z-index: 5 !important;
}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
`;

const App: React.FC<AppProps> = ({dbData, source, width, height, options, influxData}) => {
  
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      //@ts-ignore
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);

  function isWithinDifference(unixTimestamp: number, differenceInSeconds: number) {

    // Convert the Unix timestamp to a JavaScript Date object
    const timestampDate = new Date(unixTimestamp * 1000);

    // Get the current time
    const currentDate = new Date();

    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;
    // Compare and return
    
    // Compare and return
    if (timeDifference <= 30) {
        return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
        return "#CA51EC";
    } else {
        return "rgb(68, 169, 241)"; //blue -- stale data
    }
  }

  const getDataFromSource = useCallback((mne: string) => {
    const mneColumn = dbData.find(column => column.name === "mne");
    if (!mneColumn) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne column not found
    }
  
    const mneIndex = mneColumn.values.indexOf(mne);
    if (mneIndex === -1) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne not found
    }
  
    // Safely get the other columns 
    const limitColumn = dbData.find(column => column.name === "limit");
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const unitColumn = dbData.find(column => column.name === "units");
    const timeColumn = dbData.find(column => column.name === "tInsert");
  
    const limit = limitColumn ? limitColumn.values[mneIndex] : null;
    let cnvValue = cnvValueColumn ? cnvValueColumn.values[mneIndex] : null;
    let unit = unitColumn ? unitColumn.values[mneIndex] : null;
    let live = timeColumn ? isWithinDifference(timeColumn.values[mneIndex], 30) : "";

    // Append the degree symbol tothe unit if the unit is 'C'
    if (unit === 'C' || unit === 'degC') {
      unit = '\u00B0C';
    }else if(unit === 'packets'){
      unit = 'P'
    }else if(unit === 'sec'){
      unit = 'S'
    }else if(unit === 'None' || unit === 'na' || unit === 'enum'){
      unit = null
    }else if(unit === 'bool'){
      unit = 'B'
    }else if(unit === 'count' || unit === 'Counts'){
      unit = 'Cnt'
    }else if(unit === 'rad/s'){
      unit = 'r/s'
    }
  
    // Special handling for 'WFI HV' and 'NFI HV'
    if (mne === 'tcs_spoc_rtd_n2_bottle_probe_1_t' || mne === 'tcs_spoc_rtd_n2_bottle_probe_2_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_3_t'|| mne === 'tcs_spoc_rtd_n2_bottle_probe_4_t') {
      cnvValue = parseFloat(cnvValue).toExponential(2);
    } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
      // Check if cnvValue is a number and convert/round it if s
      const numValue = parseFloat(cnvValue);
      const [integerPart, decimalPart] = numValue.toString().split('.');

      // Convert to exponential form if the integer part is too long
        if (integerPart.length > 7) {
      cnvValue = numValue.toExponential(2);
        } else if (decimalPart) {
          // It's a float, parse and keep 2 decimal places
          cnvValue = numValue.toFixed(2);
        }
    }
  
    return { limit, cnvValue, unit, live, mne };
  },[dbData]);

  // Function to create the new dictionary
  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, TelemetryData> = {};
  
    Object.entries(nameToMne).forEach(([name, mne]) => {
      let newMne = mne + "_agg"; // First try with _agg added
      let data = getDataFromSource(newMne.toLowerCase());

      // If the cnvValue is null or empty, try with the regular mne
      if (!data.cnvValue) {
        newMne = mne + "_sh"; // First try with _agg added
        data = getDataFromSource(newMne.toLowerCase());
      }
  
      // If the cnvValue is null or empty, try with the regular mne
      if (!data.cnvValue) {
        newMne = mne; // Use the regular mnemonic
        data = getDataFromSource(newMne.toLowerCase());
      }
  
      telemetryDict[name] = {
        limit: data.limit,
        mne: data.mne,
        telemetry: data.cnvValue,
        unit: data.unit,
        live: data.live,
        spacecraft: source ? source : ""
      };
    });
  
    return telemetryDict;
  }, [getDataFromSource, nameToMne, source, nameToMne]);
  

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'RWD', type: 'rw',parentNode:'', position:{x:4050, y:2000}, data:{name:'RWD', value:{"I":{'dbData':telemetryDictionary['RWD Curr'],'fname':''}, "V":{'dbData':telemetryDictionary['RWD Volt'],'fname':''}, "HWS":{'dbData':telemetryDictionary['RWD HW Speed'],'fname':'Reaction Wheel Speed'}, "HWT":{'dbData':telemetryDictionary['RWD HW Temp'],'fname':'Motor Wind Temp'}}}},
    { id: 'RWC', type: 'rw',parentNode:'', position:{x:4050, y:1450}, data:{name:'RWC', value:{"I":{'dbData':telemetryDictionary['RWC Curr'],'fname':''}, "V":{'dbData':telemetryDictionary['RWC Volt'],'fname':''}, "HWS":{'dbData':telemetryDictionary['RWC HW Speed'],'fname':'Reaction Wheel Speed'}, "HWT":{'dbData':telemetryDictionary['RWC HW Temp'],'fname':'Motor Wind Temp'}}}},
    { id: 'RWB', type: 'rw',parentNode:'', position:{x:4050, y:900}, data:{name:'RWB', value:{"I":{'dbData':telemetryDictionary['RWB Curr'],'fname':''}, "V":{'dbData':telemetryDictionary['RWB Volt'],'fname':''}, "HWS":{'dbData':telemetryDictionary['RWB HW Speed'],'fname':'Reaction Wheel Speed'}, "HWT":{'dbData':telemetryDictionary['RWB HW Temp'],'fname':'Motor Wind Temp'}}}},
    { id: 'RWA', type: 'rw',parentNode:'', position:{x:4050, y:350}, data:{name:'RWA', value:{"I":{'dbData':telemetryDictionary['RWA Curr'],'fname':''}, "V":{'dbData':telemetryDictionary['RWA Volt'],'fname':''}, "HWS":{'dbData':telemetryDictionary['RWA HW Speed'],'fname':'Reaction Wheel Speed'}, "HWT":{'dbData':telemetryDictionary['RWA HW Temp'],'fname':'Motor Wind Temp'}}}},
    
    { id: 'RCSPanel', type: 'rcsPanel', position:{x:2100, y:400}, data:{'source':source}},
    { id: 'Regbox', type: 'regbox',parentNode:'RCSPanel', position:{x:700, y:650}, data:{}},  
    { id: 'RCSA', type: 'rcsa',parentNode:'RCSPanel', position:{x:50, y:1350}, data:{name:'RCSA'}},
    { id: 'RCSB', type: 'rcsa',parentNode:'RCSPanel', position:{x:950, y:1350}, data:{name:'RCSB'}},
    { id: 'RCSA Htr', type: 'heater',parentNode:'RCSA', position:{x:150, y:25}, data:{'en':telemetryDictionary['RCSA Htr En']}},
    { id: 'RCSB Htr', type: 'heater',parentNode:'RCSB', position:{x:150, y:25}, data: {'en':telemetryDictionary['RCSB Htr En']}},
    { id: 'RCSA VB', type: 'valuebox',parentNode:'RCSA', position:{x:450, y:25}, data:{value:{T:{'dbData':telemetryDictionary['RCSA T'],'fname':''}}}},
    { id: 'RCSB VB', type: 'valuebox',parentNode:'RCSB', position:{x:450, y:25}, data: {value:{"T":{'dbData':telemetryDictionary['RCSB T'],'fname':''}}}},

    { id: 'ManifordC1', type: 'manifoldC',parentNode:'RCSPanel', position:{x:50, y:250}, data:{}},
    { id: 'ManifordD1', type: 'manifoldD',parentNode:'RCSPanel', position:{x:500, y:250}, data:{}},
    { id: 'ManifordD2', type: 'manifoldD',parentNode:'RCSPanel', position:{x:900, y:250}, data:{}},
    { id: 'ManifordC2', type: 'manifoldC',parentNode:'RCSPanel', position:{x:1300, y:250}, data:{}},

    { id: 'RCSThruster', type: 'rcsThruster',parentNode:'RCSA', position:{x:250, y:400}, data:{name:"RCSA Thruster"}},    
    { id: 'RCSThrusterB', type: 'rcsThruster',parentNode:'RCSB', position:{x:250, y:400}, data:{name:"RCSB Thruster"}},    

    { id: 'SV5', type: 'sv',parentNode:'RCSB', position:{x:50, y:200}, data:{name:'1B'}},
    { id: 'SV6', type: 'sv',parentNode:'RCSB', position:{x:250, y:200}, data:{name:'2B'}},
    { id: 'SV7', type: 'sv',parentNode:'RCSB', position:{x:450, y:200}, data:{name:'3B'}},
    { id: 'SV8', type: 'sv',parentNode:'RCSB', position:{x:650, y:200}, data:{name:'4B'}},

    { id: 'SV1', type: 'sv',parentNode:'RCSA', position:{x:50, y:200}, data:{name:'1A'}},
    { id: 'SV2', type: 'sv',parentNode:'RCSA', position:{x:250, y:200}, data:{name:'2A'}},
    { id: 'SV3', type: 'sv',parentNode:'RCSA', position:{x:450, y:200}, data:{name:'3A'}},
    { id: 'SV4', type: 'sv',parentNode:'RCSA', position:{x:650, y:200}, data:{name:'4A'}},

    { id: 'FilterH2', type: 'filterH',parentNode:'RCSA', position:{x:350, y:50}, data:{}},
    { id: 'FilterH3', type: 'filterH',parentNode:'RCSB', position:{x:350, y:50}, data:{}},

    //{ id: 'RCSPT2', type: 'pt',parentNode:'Regbox', position:{x:165, y:650}, data:{ value:{}}},
    { id: 'RCSReg', type: 'rcsReg',parentNode:'Regbox', position:{x:138, y:150}, data:{name:'He Regulator', value:{}}},
    { id: 'RCSPT2VB', type: 'pt',parentNode:'RCSPanel', position:{x:250, y:1000}, data:{name:'',className:'RCST2Container', value:{'En':{'dbData':telemetryDictionary['Gn2 Regout PT En'],'fname':''},'P':{'dbData':telemetryDictionary['Gn2 Regout PT P'],'fname':''},'I':{'dbData':telemetryDictionary['Gn2 Regout PT I'],'fname':''}}}},
    //{ id: 'RCSPT1', type: 'pt',parentNode:'Regbox', position:{x:165, y:50}, data:{}},
    { id: 'RCSPT1VB', type: 'pt',parentNode:'RCSPanel', position:{x:250, y:650}, data:{className:'RCST1Container', value:{'En*':{'dbData':telemetryDictionary['Gn2 Bottle PT En'],'fname':''},'P':{'dbData':telemetryDictionary['Gn2 Bottle PT P'],'fname':''},'I':{'dbData':telemetryDictionary['Gn2 Bottle PT I'],'fname':''},}}},

    { id: 'N2T4', type: 'tank',parentNode:'RCSPanel', position:{x:1370, y:50}, data:{name:'N2T4',value:{}}},
    { id: 'N2T4Temp', type: 'temp',parentNode:'ManifordC2', position:{x:20, y:200}, data:{name:'N2T4',value:{'T':{'dbData':telemetryDictionary['N2 T4'],'fname':''}}}},
    { id: 'FilterH1', type: 'filterH',parentNode:'ManifordC2', position:{x:275, y:125}, data:{}},

    { id: 'N2T3', type: 'tank',parentNode:'RCSPanel', position:{x:970, y:50}, data:{name:'N2T3',value:{}}},
    { id: 'N2T3Temp', type: 'temp',parentNode:'ManifordD2', position:{x:20, y:200}, data:{name:'N2T3',value:{'T':{'dbData':telemetryDictionary['N2 T3'],'fname':''}}}},
  
    { id: 'N2T2', type: 'tank',parentNode:'RCSPanel', position:{x:570, y:50}, data:{name:'N2T2',value:{}}},
    { id: 'N2T2Temp', type: 'temp',parentNode:'ManifordD1', position:{x:20, y:200}, data:{name:'N2T2',value:{'T':{'dbData':telemetryDictionary['N2 T2'],'fname':''}}}},
    
    { id: 'N2T1', type: 'tank',parentNode:'RCSPanel', position:{x:200, y:50}, data:{name:'N2T1',value:{}}},
    { id: 'N2T1Temp', type: 'temp',parentNode:'ManifordC1', position:{x:100, y:200}, data:{name:'N2T1',value:{'T':{'dbData':telemetryDictionary['N2 T1'],'fname':''}}}},
    { id: 'FilterV1', type: 'filterV',parentNode:'ManifordC1', position:{x:25, y:25}, data:{}},

    
    { id: 'N2T1 SHtr', type: 'heater',parentNode:'RCSPanel', position:{x:355, y:150}, data:{name:'S1 Htr', 'en':telemetryDictionary['N2 T1 SHtr En']}},
    { id: 'N2T1 NHtr', type: 'heater',parentNode:'RCSPanel', position:{x:50, y:50}, data:{name:'N1 Htr', 'en':telemetryDictionary['N2 T1 NHtr En']}},
    { id: 'N2T2 SHtr', type: 'heater',parentNode:'RCSPanel', position:{x:725, y:150}, data:{name:'S2 Htr', 'en':telemetryDictionary['N2 T2 SHtr En']}},
    { id: 'N2T2 NHtr', type: 'heater',parentNode:'RCSPanel', position:{x:425, y:50}, data:{name:'N2 Htr', 'en':telemetryDictionary['N2 T2 NHtr En']}},
    { id: 'N2T3 SHtr', type: 'heater',parentNode:'RCSPanel', position:{x:1125, y:150}, data:{name:'S3 Htr', 'en':telemetryDictionary['N2 T3 SHtr En']}},
    { id: 'N2T3 NHtr', type: 'heater',parentNode:'RCSPanel', position:{x:825, y:50}, data:{name:'N3 Htr', 'en':telemetryDictionary['N2 T3 NHtr En']}},
    { id: 'N2T4 SHtr', type: 'heater',parentNode:'RCSPanel', position:{x:1525, y:150}, data:{name:'S4 Htr', 'en':telemetryDictionary['N2 T4 SHtr En']}},
    { id: 'N2T4 NHtr', type: 'heater',parentNode:'RCSPanel', position:{x:1225, y:50}, data:{name:'N4 Htr', 'en':telemetryDictionary['N2 T4 NHtr En']}},

    
    { id: 'Panel', type: 'panel', position:{x:50, y:50}, data:{'source':source}},
    { id: 'EngAssy', type: 'engAssy',parentNode:'Panel', position:{x:25, y:1820}, data:{}},
    { id: 'OxBox', type: 'oxBox',parentNode:'Panel', position:{x:75, y:725}, data:{}},
    { id: 'FuBox', type: 'oxBox',parentNode:'Panel', position:{x:1075, y:725}, data:{}},

    { id: 'Spoc', type: 'spoc',parentNode:'', position:{x:4000, y:50}, data:{name:'Spoc',pripwr:telemetryDictionary['Spoc A Volt'],secpwr:telemetryDictionary['Spoc B Volt'], value:{'SpocA Volt':{'dbData':telemetryDictionary['Spoc A Volt'], 'fname':''},'SpocB Volt':{'dbData':telemetryDictionary['Spoc B Volt'], 'fname':''} }}},  
    { id: 'PIU', type: 'htextbox',parentNode:'', position:{x:2150, y:50}, data:{name:'PIU', className:'piuBox',value:{'Cmd Err Ct':{'dbData':telemetryDictionary['Piu Cmd Err Ct'],'fname':''},'Cmd Succ Ct':{'dbData':telemetryDictionary['Piu Cmd Succ Ct'],'fname':''}, "Cl Pwr En":{'dbData':telemetryDictionary['Piu Cl Pwr En'],'fname':''}, "Lv Adc En":{'dbData':telemetryDictionary['Piu Lv Adc En'],'fname':''},"Lv Adc Tlm En":{'dbData':telemetryDictionary['Piu Lv Adc Tlm En'],'fname':''}, "Main Pwr En":{'dbData':telemetryDictionary['Piu Main Pwr En'],'fname':''}, "Pwr I":{'dbData':telemetryDictionary['Piu Pwr I'],'fname':''}, "T":{'dbData':telemetryDictionary['Piu T'],'fname':''}}}},
    { id: 'PropThruster', type: 'propThruster',parentNode:'EngAssy', position:{x:345, y:425}, data:{value:{"Burn":{'dbData':telemetryDictionary['Burn Seq'],'fname':'Burn Sequence State'},'OPE':{'dbData':telemetryDictionary['Ox Pri En'],'fname':'Ox Pri En'},'OSE':{'dbData':telemetryDictionary['Ox Sec En'],'fname':'Ox Sec En'},'FPE':{'dbData':telemetryDictionary['Fu Pri En'],'fname':'Fu Pri En'},'FSE':{'dbData':telemetryDictionary['Fu Sec En'],'fname':'Fu Sec En'},'OPI':{'dbData':telemetryDictionary['Ox Pri I'],'fname':'Ox Pri I'},'OSI':{'dbData':telemetryDictionary['Ox Sec I'],'fname':'Ox Sec I'},'FPI':{'dbData':telemetryDictionary['Fu Pri I'],'fname':'Fu Pri I'},'FSI':{'dbData':telemetryDictionary['Fu Sec I'],'fname':'Fu Sec I'},'OAC':{'dbData':telemetryDictionary['Ox Act Count'],'fname':'Ox Actuated Count'}, 'OT':{'dbData':telemetryDictionary['Ox T'],'fname':'Ox Temp'},'FAC':{'dbData':telemetryDictionary['Fu Act Count'],'fname':'Fu Actuated Count'},'FT':{'dbData':telemetryDictionary['Fu T'],'fname':'Fu Temp'} }}},
    { id: 'TVCZ', type: 'tvcz',parentNode:'EngAssy', position:{x:1575, y:400}, data:{name:'TVCZ', value:{'En':{'dbData':telemetryDictionary['TVCZ En'],'fname':''},'I':{'dbData':telemetryDictionary['TVCZ Poweri'],'fname':''},'OM':{'dbData':telemetryDictionary['TVCZ Op Mode'],'fname':'Operating Mode'}}}},
    { id: 'MFV', type: 'mValve',parentNode:'EngAssy', position:{x: 950, y:100}, data:{name:'MFV', className:'mfvContainer',value:{'PCC':{'dbData':telemetryDictionary['MFV Pri Cycle Cnt'],'fname':''},'SCC':{'dbData':telemetryDictionary['MFV Sec Cycle Cnt'],'fname':''},'PVS':{'dbData':telemetryDictionary['MFV Pri Valve State'],'fname':'Pri Valve State'},'SVS':{'dbData':telemetryDictionary['MFV Sec Valve State'],'fname':'Sec Valve State'}}}},
    { id: 'MOV', type: 'mValve',parentNode:'EngAssy', position:{x:645, y:100}, data:{name:'MOV', className:'movContainer',value:{'PCC':{'dbData':telemetryDictionary['MOV Pri Cycle Cnt'],'fname':'Pri Cycle Cnt'},'SCC':{'dbData':telemetryDictionary['MOV Sec Cycle Cnt'],'fname':'Sec Cycle Cnt'},'PVS':{'dbData':telemetryDictionary['MOV Pri Valve State'],'fname':'Pri Valve State'},'SVS':{'dbData':telemetryDictionary['MOV Sec Valve State'],'fname':'Sec Valve State'}}}},
    { id: 'TVCX', type: 'tvcx',parentNode:'EngAssy', position:{x:25, y:400}, data:{name:'TVCX', value:{'En':{'dbData':telemetryDictionary['TVCX En'],'fname':''},'I':{'dbData':telemetryDictionary['TVCX Poweri'],'fname':''},'OM':{'dbData':telemetryDictionary['TVCX Op Mode'],'fname':'Operating Mode'}}}},
    
    { id: 'FH2', type: 'hose',parentNode:'EngAssy', position:{x:1440, y:100}, data:{name:'Fu Fh',className:'fh2Container', value:{'T':{'dbData':telemetryDictionary['Fu Fh 2 Temp'],'fname':''}}}},
    { id: 'FH1', type: 'hose',parentNode:'EngAssy', position:{x:145, y:100}, data:{name:'Ox Fh',className:'fh1Container', value:{'T':{'dbData':telemetryDictionary['Ox Fh 1 Temp'],'fname':''}}}},
    
    { id: 'PV6', type: 'pv',parentNode:'FuBox', position:{x:260, y:800}, data:{name:'Fu Pyro',className:'pv6Container',en:telemetryDictionary['Fu Pyro En'],status:telemetryDictionary['Fu Pyro Status'], value:{'V':{'dbData':telemetryDictionary['Fu Pyro Volt'],'fname':''}}}},
    { id: 'PV5', type: 'pv',parentNode:'OxBox', position:{x:285, y:800}, data:{name:'Ox Pyro',className:'pv5Container',en:telemetryDictionary['Ox Pyro En'],status:telemetryDictionary['Ox Pyro Status'], value:{'V':{'dbData':telemetryDictionary['Ox Pyro Volt'],'fname':''}}}},

    { id: 'PT8', type: 'pt',parentNode:'FuBox', position:{x:550, y:975}, data:{name:'Fu Pt', value:{'En':{'dbData':telemetryDictionary['Fu Line Pt'],'fname':''}, 'P':{'dbData':telemetryDictionary['Fu Line P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Fu Line I'],'fname':''}}}},
    { id: 'PT7', type: 'pt',parentNode:'OxBox', position:{x:25, y:975}, data:{name:'Ox Pt', value:{'En':{'dbData':telemetryDictionary['Ox Line Pt'],'fname':''}, 'P':{'dbData':telemetryDictionary['Ox Line P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Ox Line I'],'fname':''}}}},

    { id: 'FuT2 SHtr', type: 'heater',parentNode:'FuBox', position:{x:675, y:675}, data:{name:'S2 Htr', 'en':telemetryDictionary['Fu T2 SHtr En']}},
    { id: 'FuT2 NHtr', type: 'heater',parentNode:'FuBox', position:{x:375, y:575}, data:{name:'N2 Htr', 'en':telemetryDictionary['Fu T2 NHtr En']}},
    { id: 'FuT1 SHtr', type: 'heater',parentNode:'FuBox', position:{x:275, y:675}, data:{name:'S1 Htr', 'en':telemetryDictionary['Fu T1 SHtr En']}},
    { id: 'FuT1 NHtr', type: 'heater',parentNode:'FuBox', position:{x:-25, y:575}, data:{name:'N1 Htr', 'en':telemetryDictionary['Fu T1 NHtr En']}},

    { id: 'OxT2 SHtr', type: 'heater',parentNode:'OxBox', position:{x:700, y:675}, data:{name:'S2 Htr', 'en':telemetryDictionary['Ox T2 SHtr En']}},
    { id: 'OxT2 NHtr', type: 'heater',parentNode:'OxBox', position:{x:400, y:575}, data:{name:'N2 Htr', 'en':telemetryDictionary['Ox T2 NHtr En']}},
    { id: 'OxT1 SHtr', type: 'heater',parentNode:'OxBox', position:{x:300, y:675}, data:{name:'S1 Htr', 'en':telemetryDictionary['Ox T1 SHtr En']}},
    { id: 'OxT1 NHtr', type: 'heater',parentNode:'OxBox', position:{x:0, y:575}, data:{name:'N1 Htr', 'en':telemetryDictionary['Ox T1 NHtr En']}},

    { id: 'FUT2', type: 'pmdTank',parentNode:'FuBox', position:{x:525, y:570}, data:{name:'FuT2', mass:{'initial':telemetryDictionary['Fu Ini Mass'],'remaining':telemetryDictionary['Fu Rem Mass']}}},
    { id: 'FUT1', type: 'pmdTank',parentNode:'FuBox', position:{x:125, y:570}, data:{name:'FuT1', mass:{'initial':telemetryDictionary['Fu Ini Mass'],'remaining':telemetryDictionary['Fu Rem Mass']}}},

    { id: 'OXT2', type: 'pmdTank',parentNode:'OxBox', position:{x:545, y:570}, data:{name:'OxT2', mass:{'initial':telemetryDictionary['Ox Ini Mass'],'remaining':telemetryDictionary['Ox Rem Mass']}}},
    { id: 'OXT1', type: 'pmdTank',parentNode:'OxBox', position:{x:150, y:570}, data:{name:'OxT1', mass:{'initial':telemetryDictionary['Ox Ini Mass'],'remaining':telemetryDictionary['Ox Rem Mass']}}},
    
    { id: 'PT6', type: 'pt',parentNode:'FuBox', position:{x:650, y:380}, data:{className:'smallPt',value:{'En':{'dbData':telemetryDictionary['Fu Tank 2 Pt'],'fname':''},'P':{'dbData':telemetryDictionary['Fu Tank 2 P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Fu Tank 2 I'],'fname':''}}}},
    { id: 'PT4', type: 'pt',parentNode:'FuBox', position:{x:-75, y:380}, data:{name:'Fu Pt',className:'smallPt', value:{'En':{'dbData':telemetryDictionary['Fu Tank 1 Pt'],'fname':''},'P':{'dbData':telemetryDictionary['Fu Tank 1 P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Fu Tank 1 I'],'fname':''}}}},
    
    { id: 'PT5', type: 'pt',parentNode:'OxBox', position:{x:675, y:380}, data:{name:'Ox Pt',className:'smallPt', value:{'En':{'dbData':telemetryDictionary['Ox Tank 2 Pt'],'fname':''},'P':{'dbData':telemetryDictionary['Ox Tank 2 P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Ox Tank 2 I'],'fname':''}}}},
    { id: 'PT3', type: 'pt',parentNode:'OxBox', position:{x:-50, y:380}, data:{name:'Ox Pt',className:'smallPt', value:{'En':{'dbData':telemetryDictionary['Ox Tank 1 Pt'],'fname':''},'P':{'dbData':telemetryDictionary['Ox Tank 1 P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Ox Tank 1 I'],'fname':''}}}},
    
    { id: 'FH3', type: 'hose',parentNode:'OxBox', position:{x:217, y:200}, data:{name:'Ox Fh', value:{}}},
    { id: 'FH4', type: 'hose',parentNode:'OxBox', position:{x:612, y:200}, data:{name:'Ox Fh', value:{}}},
    { id: 'FH5', type: 'hose',parentNode:'FuBox', position:{x:192, y:200}, data:{name:'Fu Fh', value:{}}},
    { id: 'FH6', type: 'hose',parentNode:'FuBox', position:{x:592, y:200}, data:{name:'Fu Fh', value:{}}},

    { id: 'HeRegOut', type: 'pt',parentNode:'Panel', position:{x:870, y:800}, data:{name:'', value:{'En':{'dbData':telemetryDictionary['He Reg Out En'],'fname':'Out En'},'P':{'dbData':telemetryDictionary['He Reg Out Pwr'],'fname':'Out P'}, 'I':{'dbData':telemetryDictionary['He Reg Out I'],'fname':'Out I'}}}},
    { id: 'HeRegVB', type: 'valuebox',parentNode:'Panel', position:{x:550, y:500}, data:{name:'', value:{'T':{'dbData':telemetryDictionary['He Reg Temp'],'fname':''}}}},
    { id: 'HeReg', type: 'reg',parentNode:'Panel', position:{x:585, y:450}, data:{name:'', value:{}}},

    { id: 'LV2', type: 'lv',parentNode:'FuBox', position:{x:265, y:50}, data:{name:'LV2', value:{'I':{'dbData':telemetryDictionary['LV2 Fu Curr'],'fname':''},'P':{'dbData':telemetryDictionary['LV2 Fu P'],'fname':''}}}},
    { id: 'LV1', type: 'lv',parentNode:'OxBox', position:{x:285, y:50}, data:{name:'LV1', value:{'I':{'dbData':telemetryDictionary['LV1 Ox Curr'],'fname':''},'P':{'dbData':telemetryDictionary['LV1 Ox P'],'fname':''}}}},

    { id: 'PV2', type: 'pv',parentNode:'Panel', position:{x:950, y:340}, data:{name:'He PV2 Pyro',className:'pv2Container', en:telemetryDictionary['He PV2 Pyro En'],status:telemetryDictionary['He PV2 Pyro Status']}},
    { id: 'PV2VB', type: 'valuebox',parentNode:'Panel', position:{x:1150, y:340}, data:{value:{'V':{'dbData':telemetryDictionary['He Pyro Volt'],'fname':''}}}},

    { id: 'PT1', type: 'pt',parentNode:'Panel', position:{x:600, y:225}, data:{name:'He', value:{'En':{'dbData':telemetryDictionary['He Bottle PT En'],'fname':''}, 'P':{'dbData':telemetryDictionary['He Bottle PT P'],'fname':''}, 'I':{'dbData':telemetryDictionary['He Bottle I'],'fname':''}}}},
    { id: 'HeT2', type: 'tank',parentNode:'Panel', position:{x:1375, y:75}, data:{name:'HeT2',className:'het2Container', value:{'T':{'dbData':telemetryDictionary['He Bottle 2 Temp'],'fname':''}}}},
    { id: 'HeT1 Htr', type: 'heater',parentNode:'Panel', position:{x:575, y:50}, data:{name:'H1 htr','en':telemetryDictionary['HeB1 Htr En']}},
    { id: 'HeT2 Htr', type: 'heater',parentNode:'Panel', position:{x:1225, y:50}, data: {name:'H2 htr','en':telemetryDictionary['HeB2 Htr En']}},
    { id: 'HeT1', type: 'tank',parentNode:'Panel', position:{x:100, y:75}, data:{name:'HeT1',className:'het1Container', value:{'T':{'dbData':telemetryDictionary['He Bottle 1 Temp'],'fname':''}}}},
    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:50, y:-100}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

  ]);

  const createNodes = (telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'RWD', type: 'rw',parentNode:'', position:{x:4050, y:2000}, data:{name:'RWD', value:{"I":{'dbData':telemetryDictionary['RWD Curr'],'fname':''}, "V":{'dbData':telemetryDictionary['RWD Volt'],'fname':''}, "HWS":{'dbData':telemetryDictionary['RWD HW Speed'],'fname':'Reaction Wheel Speed'}, "HWT":{'dbData':telemetryDictionary['RWD HW Temp'],'fname':'Motor Wind Temp'}}}},
      { id: 'RWC', type: 'rw',parentNode:'', position:{x:4050, y:1450}, data:{name:'RWC', value:{"I":{'dbData':telemetryDictionary['RWC Curr'],'fname':''}, "V":{'dbData':telemetryDictionary['RWC Volt'],'fname':''}, "HWS":{'dbData':telemetryDictionary['RWC HW Speed'],'fname':'Reaction Wheel Speed'}, "HWT":{'dbData':telemetryDictionary['RWC HW Temp'],'fname':'Motor Wind Temp'}}}},
      { id: 'RWB', type: 'rw',parentNode:'', position:{x:4050, y:900}, data:{name:'RWB', value:{"I":{'dbData':telemetryDictionary['RWB Curr'],'fname':''}, "V":{'dbData':telemetryDictionary['RWB Volt'],'fname':''}, "HWS":{'dbData':telemetryDictionary['RWB HW Speed'],'fname':'Reaction Wheel Speed'}, "HWT":{'dbData':telemetryDictionary['RWB HW Temp'],'fname':'Motor Wind Temp'}}}},
      { id: 'RWA', type: 'rw',parentNode:'', position:{x:4050, y:350}, data:{name:'RWA', value:{"I":{'dbData':telemetryDictionary['RWA Curr'],'fname':''}, "V":{'dbData':telemetryDictionary['RWA Volt'],'fname':''}, "HWS":{'dbData':telemetryDictionary['RWA HW Speed'],'fname':'Reaction Wheel Speed'}, "HWT":{'dbData':telemetryDictionary['RWA HW Temp'],'fname':'Motor Wind Temp'}}}},
      
      { id: 'RCSPanel', type: 'rcsPanel', position:{x:2100, y:400}, data:{'source':source}},
      { id: 'Regbox', type: 'regbox',parentNode:'RCSPanel', position:{x:700, y:650}, data:{}},  
      { id: 'RCSA', type: 'rcsa',parentNode:'RCSPanel', position:{x:50, y:1350}, data:{name:'RCSA'}},
      { id: 'RCSB', type: 'rcsa',parentNode:'RCSPanel', position:{x:950, y:1350}, data:{name:'RCSB'}},
      { id: 'RCSA Htr', type: 'heater',parentNode:'RCSA', position:{x:150, y:25}, data:{'en':telemetryDictionary['RCSA Htr En']}},
      { id: 'RCSB Htr', type: 'heater',parentNode:'RCSB', position:{x:150, y:25}, data: {'en':telemetryDictionary['RCSB Htr En']}},
      { id: 'RCSA VB', type: 'valuebox',parentNode:'RCSA', position:{x:450, y:25}, data:{value:{T:{'dbData':telemetryDictionary['RCSA T'],'fname':''}}}},
      { id: 'RCSB VB', type: 'valuebox',parentNode:'RCSB', position:{x:450, y:25}, data: {value:{"T":{'dbData':telemetryDictionary['RCSB T'],'fname':''}}}},
  
      { id: 'ManifordC1', type: 'manifoldC',parentNode:'RCSPanel', position:{x:50, y:250}, data:{}},
      { id: 'ManifordD1', type: 'manifoldD',parentNode:'RCSPanel', position:{x:500, y:250}, data:{}},
      { id: 'ManifordD2', type: 'manifoldD',parentNode:'RCSPanel', position:{x:900, y:250}, data:{}},
      { id: 'ManifordC2', type: 'manifoldC',parentNode:'RCSPanel', position:{x:1300, y:250}, data:{}},

      { id: 'RCSThruster', type: 'rcsThruster',parentNode:'RCSA', position:{x:250, y:400}, data:{name:"RCSA Thruster"}},    
      { id: 'RCSThrusterB', type: 'rcsThruster',parentNode:'RCSB', position:{x:250, y:400}, data:{name:"RCSB Thruster"}},    

      { id: 'SV5', type: 'sv',parentNode:'RCSB', position:{x:50, y:200}, data:{name:'1B'}},
      { id: 'SV6', type: 'sv',parentNode:'RCSB', position:{x:250, y:200}, data:{name:'2B'}},
      { id: 'SV7', type: 'sv',parentNode:'RCSB', position:{x:450, y:200}, data:{name:'3B'}},
      { id: 'SV8', type: 'sv',parentNode:'RCSB', position:{x:650, y:200}, data:{name:'4B'}},

      { id: 'SV1', type: 'sv',parentNode:'RCSA', position:{x:50, y:200}, data:{name:'1A'}},
      { id: 'SV2', type: 'sv',parentNode:'RCSA', position:{x:250, y:200}, data:{name:'2A'}},
      { id: 'SV3', type: 'sv',parentNode:'RCSA', position:{x:450, y:200}, data:{name:'3A'}},
      { id: 'SV4', type: 'sv',parentNode:'RCSA', position:{x:650, y:200}, data:{name:'4A'}},

      { id: 'FilterH2', type: 'filterH',parentNode:'RCSA', position:{x:350, y:50}, data:{}},
      { id: 'FilterH3', type: 'filterH',parentNode:'RCSB', position:{x:350, y:50}, data:{}},

      //{ id: 'RCSPT2', type: 'pt',parentNode:'Regbox', position:{x:165, y:650}, data:{ value:{}}},
      { id: 'RCSReg', type: 'rcsReg',parentNode:'Regbox', position:{x:138, y:150}, data:{name:'He Regulator', value:{}}},
      { id: 'RCSPT2VB', type: 'pt',parentNode:'RCSPanel', position:{x:250, y:1000}, data:{name:'',className:'RCST2Container', value:{'En':{'dbData':telemetryDictionary['Gn2 Regout PT En'],'fname':''},'P':{'dbData':telemetryDictionary['Gn2 Regout PT P'],'fname':''},'I':{'dbData':telemetryDictionary['Gn2 Regout PT I'],'fname':''}}}},
      //{ id: 'RCSPT1', type: 'pt',parentNode:'Regbox', position:{x:165, y:50}, data:{}},
      { id: 'RCSPT1VB', type: 'pt',parentNode:'RCSPanel', position:{x:250, y:650}, data:{className:'RCST1Container', value:{'En*':{'dbData':telemetryDictionary['Gn2 Bottle PT En'],'fname':''},'P':{'dbData':telemetryDictionary['Gn2 Bottle PT P'],'fname':''},'I':{'dbData':telemetryDictionary['Gn2 Bottle PT I'],'fname':''},}}},

      { id: 'N2T4', type: 'tank',parentNode:'RCSPanel', position:{x:1370, y:50}, data:{name:'N2T4',value:{}}},
      { id: 'N2T4Temp', type: 'temp',parentNode:'ManifordC2', position:{x:20, y:200}, data:{name:'N2T4',value:{'T':{'dbData':telemetryDictionary['N2 T4'],'fname':''}}}},
      { id: 'FilterH1', type: 'filterH',parentNode:'ManifordC2', position:{x:275, y:125}, data:{}},

      { id: 'N2T3', type: 'tank',parentNode:'RCSPanel', position:{x:970, y:50}, data:{name:'N2T3',value:{}}},
      { id: 'N2T3Temp', type: 'temp',parentNode:'ManifordD2', position:{x:20, y:200}, data:{name:'N2T3',value:{'T':{'dbData':telemetryDictionary['N2 T3'],'fname':''}}}},
    
      { id: 'N2T2', type: 'tank',parentNode:'RCSPanel', position:{x:570, y:50}, data:{name:'N2T2',value:{}}},
      { id: 'N2T2Temp', type: 'temp',parentNode:'ManifordD1', position:{x:20, y:200}, data:{name:'N2T2',value:{'T':{'dbData':telemetryDictionary['N2 T2'],'fname':''}}}},
      
      { id: 'N2T1', type: 'tank',parentNode:'RCSPanel', position:{x:200, y:50}, data:{name:'N2T1',value:{}}},
      { id: 'N2T1Temp', type: 'temp',parentNode:'ManifordC1', position:{x:100, y:200}, data:{name:'N2T1',value:{'T':{'dbData':telemetryDictionary['N2 T1'],'fname':''}}}},
      { id: 'FilterV1', type: 'filterV',parentNode:'ManifordC1', position:{x:25, y:25}, data:{}},
  
      
      { id: 'N2T1 SHtr', type: 'heater',parentNode:'RCSPanel', position:{x:355, y:150}, data:{name:'S1 Htr', 'en':telemetryDictionary['N2 T1 SHtr En']}},
      { id: 'N2T1 NHtr', type: 'heater',parentNode:'RCSPanel', position:{x:50, y:50}, data:{name:'N1 Htr', 'en':telemetryDictionary['N2 T1 NHtr En']}},
      { id: 'N2T2 SHtr', type: 'heater',parentNode:'RCSPanel', position:{x:725, y:150}, data:{name:'S2 Htr', 'en':telemetryDictionary['N2 T2 SHtr En']}},
      { id: 'N2T2 NHtr', type: 'heater',parentNode:'RCSPanel', position:{x:425, y:50}, data:{name:'N2 Htr', 'en':telemetryDictionary['N2 T2 NHtr En']}},
      { id: 'N2T3 SHtr', type: 'heater',parentNode:'RCSPanel', position:{x:1125, y:150}, data:{name:'S3 Htr', 'en':telemetryDictionary['N2 T3 SHtr En']}},
      { id: 'N2T3 NHtr', type: 'heater',parentNode:'RCSPanel', position:{x:825, y:50}, data:{name:'N3 Htr', 'en':telemetryDictionary['N2 T3 NHtr En']}},
      { id: 'N2T4 SHtr', type: 'heater',parentNode:'RCSPanel', position:{x:1525, y:150}, data:{name:'S4 Htr', 'en':telemetryDictionary['N2 T4 SHtr En']}},
      { id: 'N2T4 NHtr', type: 'heater',parentNode:'RCSPanel', position:{x:1225, y:50}, data:{name:'N4 Htr', 'en':telemetryDictionary['N2 T4 NHtr En']}},

      
      { id: 'Panel', type: 'panel', position:{x:50, y:50}, data:{'source':source}},
      { id: 'EngAssy', type: 'engAssy',parentNode:'Panel', position:{x:25, y:1820}, data:{}},
      { id: 'OxBox', type: 'oxBox',parentNode:'Panel', position:{x:75, y:725}, data:{}},
      { id: 'FuBox', type: 'oxBox',parentNode:'Panel', position:{x:1075, y:725}, data:{}},
 
      { id: 'Spoc', type: 'spoc',parentNode:'', position:{x:4000, y:50}, data:{name:'Spoc',pripwr:telemetryDictionary['Spoc A Volt'],secpwr:telemetryDictionary['Spoc B Volt'], value:{'SpocA Volt':{'dbData':telemetryDictionary['Spoc A Volt'], 'fname':''},'SpocB Volt':{'dbData':telemetryDictionary['Spoc B Volt'], 'fname':''} }}},  
      { id: 'PIU', type: 'htextbox',parentNode:'', position:{x:2150, y:50}, data:{name:'PIU', className:'piuBox',value:{'Cmd Err Ct':{'dbData':telemetryDictionary['Piu Cmd Err Ct'],'fname':''},'Cmd Succ Ct':{'dbData':telemetryDictionary['Piu Cmd Succ Ct'],'fname':''}, "Cl Pwr En":{'dbData':telemetryDictionary['Piu Cl Pwr En'],'fname':''}, "Lv Adc En":{'dbData':telemetryDictionary['Piu Lv Adc En'],'fname':''},"Lv Adc Tlm En":{'dbData':telemetryDictionary['Piu Lv Adc Tlm En'],'fname':''}, "Main Pwr En":{'dbData':telemetryDictionary['Piu Main Pwr En'],'fname':''}, "Pwr I":{'dbData':telemetryDictionary['Piu Pwr I'],'fname':''}, "T":{'dbData':telemetryDictionary['Piu T'],'fname':''}}}},
      { id: 'PropThruster', type: 'propThruster',parentNode:'EngAssy', position:{x:345, y:425}, data:{value:{"Burn":{'dbData':telemetryDictionary['Burn Seq'],'fname':'Burn Sequence State'},'OPE':{'dbData':telemetryDictionary['Ox Pri En'],'fname':'Ox Pri En'},'OSE':{'dbData':telemetryDictionary['Ox Sec En'],'fname':'Ox Sec En'},'FPE':{'dbData':telemetryDictionary['Fu Pri En'],'fname':'Fu Pri En'},'FSE':{'dbData':telemetryDictionary['Fu Sec En'],'fname':'Fu Sec En'},'OPI':{'dbData':telemetryDictionary['Ox Pri I'],'fname':'Ox Pri I'},'OSI':{'dbData':telemetryDictionary['Ox Sec I'],'fname':'Ox Sec I'},'FPI':{'dbData':telemetryDictionary['Fu Pri I'],'fname':'Fu Pri I'},'FSI':{'dbData':telemetryDictionary['Fu Sec I'],'fname':'Fu Sec I'},'OAC':{'dbData':telemetryDictionary['Ox Act Count'],'fname':'Ox Actuated Count'}, 'OT':{'dbData':telemetryDictionary['Ox T'],'fname':'Ox Temp'},'FAC':{'dbData':telemetryDictionary['Fu Act Count'],'fname':'Fu Actuated Count'},'FT':{'dbData':telemetryDictionary['Fu T'],'fname':'Fu Temp'} }}},
      { id: 'TVCZ', type: 'tvcz',parentNode:'EngAssy', position:{x:1575, y:400}, data:{name:'TVCZ', value:{'En':{'dbData':telemetryDictionary['TVCZ En'],'fname':''},'I':{'dbData':telemetryDictionary['TVCZ Poweri'],'fname':''},'OM':{'dbData':telemetryDictionary['TVCZ Op Mode'],'fname':'Operating Mode'}}}},
      { id: 'MFV', type: 'mValve',parentNode:'EngAssy', position:{x: 950, y:100}, data:{name:'MFV', className:'mfvContainer',value:{'PCC':{'dbData':telemetryDictionary['MFV Pri Cycle Cnt'],'fname':''},'SCC':{'dbData':telemetryDictionary['MFV Sec Cycle Cnt'],'fname':''},'PVS':{'dbData':telemetryDictionary['MFV Pri Valve State'],'fname':'Pri Valve State'},'SVS':{'dbData':telemetryDictionary['MFV Sec Valve State'],'fname':'Sec Valve State'}}}},
      { id: 'MOV', type: 'mValve',parentNode:'EngAssy', position:{x:645, y:100}, data:{name:'MOV', className:'movContainer',value:{'PCC':{'dbData':telemetryDictionary['MOV Pri Cycle Cnt'],'fname':'Pri Cycle Cnt'},'SCC':{'dbData':telemetryDictionary['MOV Sec Cycle Cnt'],'fname':'Sec Cycle Cnt'},'PVS':{'dbData':telemetryDictionary['MOV Pri Valve State'],'fname':'Pri Valve State'},'SVS':{'dbData':telemetryDictionary['MOV Sec Valve State'],'fname':'Sec Valve State'}}}},
      { id: 'TVCX', type: 'tvcx',parentNode:'EngAssy', position:{x:25, y:400}, data:{name:'TVCX', value:{'En':{'dbData':telemetryDictionary['TVCX En'],'fname':''},'I':{'dbData':telemetryDictionary['TVCX Poweri'],'fname':''},'OM':{'dbData':telemetryDictionary['TVCX Op Mode'],'fname':'Operating Mode'}}}},
      
      { id: 'FH2', type: 'hose',parentNode:'EngAssy', position:{x:1440, y:100}, data:{name:'Fu Fh',className:'fh2Container', value:{'T':{'dbData':telemetryDictionary['Fu Fh 2 Temp'],'fname':''}}}},
      { id: 'FH1', type: 'hose',parentNode:'EngAssy', position:{x:145, y:100}, data:{name:'Ox Fh',className:'fh1Container', value:{'T':{'dbData':telemetryDictionary['Ox Fh 1 Temp'],'fname':''}}}},
      
      { id: 'PV6', type: 'pv',parentNode:'FuBox', position:{x:260, y:800}, data:{name:'Fu Pyro',className:'pv6Container',en:telemetryDictionary['Fu Pyro En'],status:telemetryDictionary['Fu Pyro Status'], value:{'V':{'dbData':telemetryDictionary['Fu Pyro Volt'],'fname':''}}}},
      { id: 'PV5', type: 'pv',parentNode:'OxBox', position:{x:285, y:800}, data:{name:'Ox Pyro',className:'pv5Container',en:telemetryDictionary['Ox Pyro En'],status:telemetryDictionary['Ox Pyro Status'], value:{'V':{'dbData':telemetryDictionary['Ox Pyro Volt'],'fname':''}}}},

      { id: 'PT8', type: 'pt',parentNode:'FuBox', position:{x:550, y:975}, data:{name:'Fu Pt', value:{'En':{'dbData':telemetryDictionary['Fu Line Pt'],'fname':''}, 'P':{'dbData':telemetryDictionary['Fu Line P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Fu Line I'],'fname':''}}}},
      { id: 'PT7', type: 'pt',parentNode:'OxBox', position:{x:25, y:975}, data:{name:'Ox Pt', value:{'En':{'dbData':telemetryDictionary['Ox Line Pt'],'fname':''}, 'P':{'dbData':telemetryDictionary['Ox Line P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Ox Line I'],'fname':''}}}},

      { id: 'FuT2 SHtr', type: 'heater',parentNode:'FuBox', position:{x:675, y:675}, data:{name:'S2 Htr', 'en':telemetryDictionary['Fu T2 SHtr En']}},
      { id: 'FuT2 NHtr', type: 'heater',parentNode:'FuBox', position:{x:375, y:575}, data:{name:'N2 Htr', 'en':telemetryDictionary['Fu T2 NHtr En']}},
      { id: 'FuT1 SHtr', type: 'heater',parentNode:'FuBox', position:{x:275, y:675}, data:{name:'S1 Htr', 'en':telemetryDictionary['Fu T1 SHtr En']}},
      { id: 'FuT1 NHtr', type: 'heater',parentNode:'FuBox', position:{x:-25, y:575}, data:{name:'N1 Htr', 'en':telemetryDictionary['Fu T1 NHtr En']}},

      { id: 'OxT2 SHtr', type: 'heater',parentNode:'OxBox', position:{x:700, y:675}, data:{name:'S2 Htr', 'en':telemetryDictionary['Ox T2 SHtr En']}},
      { id: 'OxT2 NHtr', type: 'heater',parentNode:'OxBox', position:{x:400, y:575}, data:{name:'N2 Htr', 'en':telemetryDictionary['Ox T2 NHtr En']}},
      { id: 'OxT1 SHtr', type: 'heater',parentNode:'OxBox', position:{x:300, y:675}, data:{name:'S1 Htr', 'en':telemetryDictionary['Ox T1 SHtr En']}},
      { id: 'OxT1 NHtr', type: 'heater',parentNode:'OxBox', position:{x:0, y:575}, data:{name:'N1 Htr', 'en':telemetryDictionary['Ox T1 NHtr En']}},

      { id: 'FUT2', type: 'pmdTank',parentNode:'FuBox', position:{x:525, y:570}, data:{name:'FuT2', mass:{'initial':telemetryDictionary['Fu Ini Mass'],'remaining':telemetryDictionary['Fu Rem Mass']}}},
      { id: 'FUT1', type: 'pmdTank',parentNode:'FuBox', position:{x:125, y:570}, data:{name:'FuT1', mass:{'initial':telemetryDictionary['Fu Ini Mass'],'remaining':telemetryDictionary['Fu Rem Mass']}}},

      { id: 'OXT2', type: 'pmdTank',parentNode:'OxBox', position:{x:545, y:570}, data:{name:'OxT2', mass:{'initial':telemetryDictionary['Ox Ini Mass'],'remaining':telemetryDictionary['Ox Rem Mass']}}},
      { id: 'OXT1', type: 'pmdTank',parentNode:'OxBox', position:{x:150, y:570}, data:{name:'OxT1', mass:{'initial':telemetryDictionary['Ox Ini Mass'],'remaining':telemetryDictionary['Ox Rem Mass']}}},
      
      { id: 'PT6', type: 'pt',parentNode:'FuBox', position:{x:650, y:380}, data:{className:'smallPt',value:{'En':{'dbData':telemetryDictionary['Fu Tank 2 Pt'],'fname':''},'P':{'dbData':telemetryDictionary['Fu Tank 2 P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Fu Tank 2 I'],'fname':''}}}},
      { id: 'PT4', type: 'pt',parentNode:'FuBox', position:{x:-75, y:380}, data:{name:'Fu Pt',className:'smallPt', value:{'En':{'dbData':telemetryDictionary['Fu Tank 1 Pt'],'fname':''},'P':{'dbData':telemetryDictionary['Fu Tank 1 P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Fu Tank 1 I'],'fname':''}}}},
      
      { id: 'PT5', type: 'pt',parentNode:'OxBox', position:{x:675, y:380}, data:{name:'Ox Pt',className:'smallPt', value:{'En':{'dbData':telemetryDictionary['Ox Tank 2 Pt'],'fname':''},'P':{'dbData':telemetryDictionary['Ox Tank 2 P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Ox Tank 2 I'],'fname':''}}}},
      { id: 'PT3', type: 'pt',parentNode:'OxBox', position:{x:-50, y:380}, data:{name:'Ox Pt',className:'smallPt', value:{'En':{'dbData':telemetryDictionary['Ox Tank 1 Pt'],'fname':''},'P':{'dbData':telemetryDictionary['Ox Tank 1 P'],'fname':''}, 'I':{'dbData':telemetryDictionary['Ox Tank 1 I'],'fname':''}}}},
      
      { id: 'FH3', type: 'hose',parentNode:'OxBox', position:{x:217, y:200}, data:{name:'Ox Fh', value:{}}},
      { id: 'FH4', type: 'hose',parentNode:'OxBox', position:{x:612, y:200}, data:{name:'Ox Fh', value:{}}},
      { id: 'FH5', type: 'hose',parentNode:'FuBox', position:{x:192, y:200}, data:{name:'Fu Fh', value:{}}},
      { id: 'FH6', type: 'hose',parentNode:'FuBox', position:{x:592, y:200}, data:{name:'Fu Fh', value:{}}},
  
      { id: 'HeRegOut', type: 'pt',parentNode:'Panel', position:{x:870, y:800}, data:{name:'', value:{'En':{'dbData':telemetryDictionary['He Reg Out En'],'fname':'Out En'},'P':{'dbData':telemetryDictionary['He Reg Out Pwr'],'fname':'Out P'}, 'I':{'dbData':telemetryDictionary['He Reg Out I'],'fname':'Out I'}}}},
      { id: 'HeRegVB', type: 'valuebox',parentNode:'Panel', position:{x:550, y:500}, data:{name:'', value:{'T':{'dbData':telemetryDictionary['He Reg Temp'],'fname':''}}}},
      { id: 'HeReg', type: 'reg',parentNode:'Panel', position:{x:585, y:450}, data:{name:'', value:{}}},

      { id: 'LV2', type: 'lv',parentNode:'FuBox', position:{x:265, y:50}, data:{name:'LV2', value:{'I':{'dbData':telemetryDictionary['LV2 Fu Curr'],'fname':''},'P':{'dbData':telemetryDictionary['LV2 Fu P'],'fname':''}}}},
      { id: 'LV1', type: 'lv',parentNode:'OxBox', position:{x:285, y:50}, data:{name:'LV1', value:{'I':{'dbData':telemetryDictionary['LV1 Ox Curr'],'fname':''},'P':{'dbData':telemetryDictionary['LV1 Ox P'],'fname':''}}}},

      { id: 'PV2', type: 'pv',parentNode:'Panel', position:{x:950, y:340}, data:{name:'He PV2 Pyro',className:'pv2Container', en:telemetryDictionary['He PV2 Pyro En'],status:telemetryDictionary['He PV2 Pyro Status']}},
      { id: 'PV2VB', type: 'valuebox',parentNode:'Panel', position:{x:1150, y:340}, data:{value:{'V':{'dbData':telemetryDictionary['He Pyro Volt'],'fname':''}}}},
  
      { id: 'PT1', type: 'pt',parentNode:'Panel', position:{x:600, y:225}, data:{name:'He', value:{'En':{'dbData':telemetryDictionary['He Bottle PT En'],'fname':''}, 'P':{'dbData':telemetryDictionary['He Bottle PT P'],'fname':''}, 'I':{'dbData':telemetryDictionary['He Bottle I'],'fname':''}}}},
      { id: 'HeT2', type: 'tank',parentNode:'Panel', position:{x:1375, y:75}, data:{name:'HeT2',className:'het2Container', value:{'T':{'dbData':telemetryDictionary['He Bottle 2 Temp'],'fname':''}}}},
      { id: 'HeT1 Htr', type: 'heater',parentNode:'Panel', position:{x:575, y:50}, data:{name:'H1 htr','en':telemetryDictionary['HeB1 Htr En']}},
      { id: 'HeT2 Htr', type: 'heater',parentNode:'Panel', position:{x:1225, y:50}, data: {name:'H2 htr','en':telemetryDictionary['HeB2 Htr En']}},
      { id: 'HeT1', type: 'tank',parentNode:'Panel', position:{x:100, y:75}, data:{name:'HeT1',className:'het1Container', value:{'T':{'dbData':telemetryDictionary['He Bottle 1 Temp'],'fname':''}}}},
      { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:50, y:-100}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

    ]}

    useEffect(() => {
      if (!guiValues.current.pbisInPlayBack) {
        return;
      }
    
      let isCancelled = false;
    
      const processData = async () => {
        // Initialize an empty object to store telemetry data
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;
    
        for (let i = 0; i < totalLoops; i++) {
          // Check if the process should stop
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
            pbTimeRef.current = key;
            const newValue = (i + 1)/totalLoops;
            pbLoopRef.current = newValue;

    
          // Loop through each item in selectedData and update the aggregatedData object
          selectedData.forEach(item => {
            // If the key already exists, update the telemetry value
            if (aggregatedData[item.name]) {
              if (item.cnvValue !== '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: "",
                unit: "",
                spacecraft: "",
                live: "rgb(68, 169, 241)",
              };
            }
          });
    
          // Create nodes from the aggregatedData object
          const newNodes = createNodes(aggregatedData);
          setNodes(newNodes);
    
    
          // Wait for 2 seconds before processing the next item
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
        guiValues.current.pbstopPlay()
      };
    
      processData();
    
      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true;
      };
    }, [influxData, guiValues.current.pbisInPlayBack, createNodes, guiValues]);

    useEffect(() => {
      if (guiValues.current.pbisInPlayBack) {
        return;
      }

      const telemetryDictionary1 = createTelemetryDictionary();
    
      const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
      setNodes(newNodes);
    }, [dbData, createTelemetryDictionary]);


  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};


  const initialEdges = [
    {id: 'edge1', source: 'HeT1',sourceHandle: 'source-right', target: 'PV2',targetHandle:'target-top-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edgeHeT2PT1', source: 'HeT2',sourceHandle: 'source-left', target: 'PV2',targetHandle:'target-top-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge2', source: 'PT1',sourceHandle: 'source-right', target: 'PV2',targetHandle:'target-left', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge3', source: 'PV2',sourceHandle: 'source-bottom-1', target: 'HeReg',targetHandle:'target-top-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge4', source: 'HeReg',sourceHandle: 'source-bottom-1', target: 'LV2',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edgeHeRegLV1', source: 'HeReg',sourceHandle: 'source-bottom-1', target: 'LV1',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge5', source: 'LV1',sourceHandle: 'source-right-1', target: 'FH4',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edgeLV1FH4', source: 'LV1',sourceHandle: 'source-left-1', target: 'FH3',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge6', source: 'LV2',sourceHandle: 'source-right-1', target: 'FH6',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge7', source: 'LV2',sourceHandle: 'source-left-1', target: 'FH5',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge8', source: 'FH5',sourceHandle: 'source-bottom', target: 'FUT1',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge9', source: 'FH6',sourceHandle: 'source-bottom', target: 'FUT2',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge10', source: 'FH3',sourceHandle: 'source-bottom', target: 'OXT1',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge11', source: 'FH4',sourceHandle: 'source-bottom', target: 'OXT2',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'PT5OXT2', source: 'PT5',sourceHandle: 'source-left', target: 'OXT2',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'PT3OXT1', source: 'PT3',sourceHandle: 'source-right', target: 'OXT1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'PT4OXT1', source: 'PT4',sourceHandle: 'source-right', target: 'FUT1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'PT6OXT2', source: 'PT6',sourceHandle: 'source-left', target: 'FUT2',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge12', source: 'FUT2',sourceHandle: 'source-bottom', target: 'PV6',targetHandle:'target-right-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge13', source: 'FUT1',sourceHandle: 'source-bottom', target: 'PV6',targetHandle:'target-left-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edgePT5PV5', source: 'OXT2',sourceHandle: 'source-bottom', target: 'PV5',targetHandle:'target-right-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edgePT3PV5', source: 'OXT1',sourceHandle: 'source-bottom', target: 'PV5',targetHandle:'target-left-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge14', source: 'PV6',sourceHandle: 'source-bottom', target: 'FH2',targetHandle:'target-top-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge15', source: 'PV5',sourceHandle: 'source-bottom', target: 'FH1',targetHandle:'target-top-2', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge16', source: 'PT8',sourceHandle: 'source-left', target: 'FH2',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge17', source: 'PT7',sourceHandle: 'source-right', target: 'FH1',targetHandle:'target-left', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge18', source: 'FH1',sourceHandle: 'source-bottom-2', target: 'MOV',targetHandle:'target-left-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge19', source: 'FH2',sourceHandle: 'source-bottom-1', target: 'MFV',targetHandle:'target-right-1', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},

    {id: 'edge20', source: 'FilterV1',sourceHandle: 'source-right', target: 'FilterH1',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edgeFPT1', source: 'FilterH1',sourceHandle: 'source-bottom', target: 'RCSReg',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge21', source: 'RCSPT1VB',sourceHandle: 'source-right', target: 'RCSReg',targetHandle:'target-left', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge22', source: 'RCSPT2VB',sourceHandle: 'source-right', target: 'RCSReg',targetHandle:'target-left', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edgeRegF3', source: 'RCSReg',sourceHandle: 'source-bottom', target: 'FilterH3',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edgeRegF2', source: 'RCSReg',sourceHandle: 'source-bottom', target: 'FilterH2',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge25', source: 'FilterH2',sourceHandle: 'source-left', target: 'SV1',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge26', source: 'FilterH2',sourceHandle: 'source-left', target: 'SV2',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge27', source: 'FilterH2',sourceHandle: 'source-right', target: 'SV3',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge28', source: 'FilterH2',sourceHandle: 'source-right', target: 'SV4',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge29', source: 'FilterH3',sourceHandle: 'source-left', target: 'SV5',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge30', source: 'FilterH3',sourceHandle: 'source-left', target: 'SV6',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge31', source: 'FilterH3',sourceHandle: 'source-right', target: 'SV7',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge32', source: 'FilterH3',sourceHandle: 'source-right', target: 'SV8',targetHandle:'target-top', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge33', source: 'SV1',sourceHandle: 'source-bottom', target: 'RCSThruster',targetHandle:'target-top-4', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge34', source: 'SV2',sourceHandle: 'source-left', target: 'RCSThruster',targetHandle:'target-top-3', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge35', source: 'SV3',sourceHandle: 'source-bottom', target: 'RCSThruster',targetHandle:'target-top-2', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge36', source: 'SV4',sourceHandle: 'source-bottom', target: 'RCSThruster',targetHandle:'target-top-5', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge37', source: 'SV5',sourceHandle: 'source-bottom', target: 'RCSThrusterB',targetHandle:'target-top-2', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge38', source: 'SV6',sourceHandle: 'source-left', target: 'RCSThrusterB',targetHandle:'target-top-3', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge39', source: 'SV7',sourceHandle: 'source-bottom', target: 'RCSThrusterB',targetHandle:'target-top-4', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge40', source: 'SV8',sourceHandle: 'source-bottom', target: 'RCSThrusterB',targetHandle:'target-top-5', type:'fluidedge2',style: { stroke: 'white',strokeWidth:5}},
    {id: 'edge41', source: 'N2T1',sourceHandle: 'source-bottom', target: 'FilterV1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge42', source: 'N2T2',sourceHandle: 'source-bottom', target: 'FilterV1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge43', source: 'N2T3',sourceHandle: 'source-bottom', target: 'FilterV1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge44', source: 'N2T4',sourceHandle: 'source-bottom', target: 'FilterV1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge45', source: 'N2T1Temp',sourceHandle: 'source-top', target: 'FilterV1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge46', source: 'N2T2Temp',sourceHandle: 'source-top', target: 'FilterV1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge47', source: 'N2T3Temp',sourceHandle: 'source-top', target: 'FilterV1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge48', source: 'N2T4Temp',sourceHandle: 'source-top', target: 'FilterV1',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge49', source: 'HeRegOut',sourceHandle: 'source-top', target: 'HeReg',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge50', source: 'Spoc',sourceHandle: 'source-left-1', target: 'PIU',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white',strokeWidth:5}, markerEnd: arrow},
    {id: 'edge51', source: 'PIU',sourceHandle: 'source-left-1', target: 'Panel',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white',strokeWidth:5}, markerEnd: arrow},
    {id: 'edge52', source: 'Spoc',sourceHandle: 'source-bottom', target: 'RCSPanel',targetHandle:'target-top', type:'customEdge',style: { stroke: 'white',strokeWidth:5}, markerEnd: arrow},
    {id: 'edge53', source: 'Spoc',sourceHandle: 'source-right', target: 'RWA',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white',strokeWidth:5}, markerEnd: arrow, data:{pwr:telemetryDictionary['RWA Pwr En']}},
    {id: 'edge54', source: 'Spoc',sourceHandle: 'source-right', target: 'RWB',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white',strokeWidth:5}, markerEnd: arrow, data:{pwr:telemetryDictionary['RWB Pwr En']}},
    {id: 'edge55', source: 'Spoc',sourceHandle: 'source-right', target: 'RWC',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white',strokeWidth:5}, markerEnd: arrow, data:{pwr:telemetryDictionary['RWC Pwr En']}},
    {id: 'edge56', source: 'Spoc',sourceHandle: 'source-right', target: 'RWD',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white',strokeWidth:5}, markerEnd: arrow, data:{pwr:telemetryDictionary['RWD Pwr En']}},
    {id: 'edge57', source: 'TVCX',sourceHandle: 'source-right-1', target: 'PropThruster',targetHandle:'target-left-1', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge58', source: 'TVCZ',sourceHandle: 'source-left-1', target: 'PropThruster',targetHandle:'target-right-1', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge59', source: 'HeRegVB',sourceHandle: 'source-right', target: 'HeReg',targetHandle:'target-left', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
    {id: 'edge60', source: 'PV2VB',sourceHandle: 'source-left', target: 'PV2',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white',strokeWidth:5,strokeDasharray:'20, 20'}},
 
 ];

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow
            onInit={onInit}
          edges={initialEdges}
          edgeTypes={edgeTypes}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.2}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true}
          zoomOnDoubleClick={true}
          fitView={true}

          />
        </div>
  
    </ReactFlowProvider>
  );

}

export default App;