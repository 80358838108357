import { css } from '@emotion/css';
export const EnvBox = css`
  width: 330px;

  & > div {
    width: 330px;

    & > div {
      width: 330px;

      & > div:nth-of-type(2){
        width: 150px;
      }
    }
  }
`;

export const BcrBox = css`
  width: 350px;
  & > div {
    width:350px;

    & > div {
      width: 350px;

      & div:nth-of-type(2) {
        width: 180px;
      }
    }
  }
`;


// Define a type for the class keys
export type ClassKey = 'EnvBox' | 'BcrBox' ;