import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styl
import React, { useState } from 'react'
import { Handle, Position } from 'reactflow';
import { determineColorNew } from './utils';

type VisibleTooltips = { [key: string]: boolean };

interface SpocProps {
  data: {
    pripwr: {
      mne: string;
      telemetry: string;
      live: string;
      limit: string;
    };
    secpwr: {
      mne: string;
      telemetry: string;
      live: string;
      limit: string;
    };
  };
}



const Spoc: React.FC<SpocProps> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const getColor = (self: string, other:string) => {
    const selfN = parseFloat(self);
    const otherN = parseFloat(other);
    if (selfN > otherN) {
        return "rgb(72, 200, 44)";
    }
    else{
      return "rgb(100,100,100)";
    }
  }

  const [showPowerInfo, setShowPowerInfo] = useState(false);
  const [showPowerInfo1, setShowPowerInfo1] = useState(false);

  const handlePowerEllipseClick = () => {
    setShowPowerInfo(!showPowerInfo);

    setTimeout(() => {setShowPowerInfo(false)}, 5000);
  };

  const handlePowerEllipseClick1 = () => {
    setShowPowerInfo1(!showPowerInfo1);

    setTimeout(() => {setShowPowerInfo1(false)}, 5000);
  };

  return (
    <div>
      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>
      
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{opacity:0, top: 90}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

      <div className={cx(scheme.textboxContainer, css`width:70px`)}>

      {data.pripwr && <div className={css`position: relative;
      `}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="60px" height="60px" viewBox="-0.5 -0.5 60 60" onClick={handlePowerEllipseClick}>
          <g>
            <g>
              <ellipse cx="30" cy="30" rx="25" ry="25" fill="rgb(38,38,38)" stroke={getColor(data.pripwr.telemetry, data.secpwr.telemetry)} pointerEvents="all" strokeWidth="6"/>
            </g>
            <g>
              <text x="30" y="45" fill={getColor(data.pripwr.telemetry, data.secpwr.telemetry)} fontFamily="Times New Roman" fontSize="50px" textAnchor="middle">A
              </text>
            </g>
          </g>
        </svg>
            {showPowerInfo && <div className={scheme.svgPopValue}>
              <div className={scheme.svgValueSpanContainer}>
                <span className={scheme.svgKey} onClick={() => handleMneNameClick("PriPower")}>
                  Pri
                  <span className={scheme.svgFnameTooltip}>Pri Power</span>
                  {visibleTooltips['PriPower'] && <span className={scheme.mnameTooltip}>{data.pripwr.mne}</span>}
                </span>
                <span 
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.pripwr.live}`,
                  scheme.classMap[determineColorNew(data.pripwr.limit)]
                )}
              >
                {data.pripwr.telemetry}
              </span>
              </div>
            </div>}
            </div>}

            {data.secpwr && <div className={css`position: relative;
            right: 0;
      `}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="60px" height="60px" viewBox="-0.5 -0.5 60 60" onClick={handlePowerEllipseClick1}>
          <g>
            <g>
              <ellipse cx="30" cy="30" rx="25" ry="25" fill="rgb(38,38,38)" stroke={getColor(data.secpwr.telemetry, data.pripwr.telemetry)} pointerEvents="all" strokeWidth="6"/>
            </g>
            <g>
              <text x="30" y="45" fill={getColor(data.secpwr.telemetry, data.pripwr.telemetry)} fontFamily="Times New Roman" fontSize="50px" textAnchor="middle">B
              </text>
            </g>
          </g>
        </svg>
            {showPowerInfo1 && <div className={scheme.svgPopValue}>
              <div className={scheme.svgValueSpanContainer}>
                <span className={scheme.svgKey} onClick={() => handleMneNameClick("SecPower")}>
                  Sec
                  <span className={scheme.svgFnameTooltip}>Sec Power</span>
                  {visibleTooltips['SecPower'] && <span className={scheme.mnameTooltip}>{data.secpwr.mne}</span>}
                </span>
                <span 
                className={cx(
                  scheme.svgValue,
                  css`color: ${data.secpwr.live}`,
                  scheme.classMap[determineColorNew(data.secpwr.limit)]
                )}
              >
                {data.secpwr.telemetry}
              </span>
              </div>
            </div>}
            </div>}

      </div>
    </div>
  )
}

export default Spoc;
