import { css } from '@emotion/css';
export const pv2Container = css`
display: flex;
width: 450px;
justify-content: space-between;
align-items: center;

`;

export const pv5Container = css`
display: flex;
flex-direction: column-reverse;
height: 125px;
justify-content: space-between;
`;

export const pv6Container = css`
display: flex;
flex-direction: column-reverse;
height: 125px;
justify-content: space-between;
`;


// Define a type for the class key
export type ClassKey = 'pv2Container' | 'pv5Container' | 'pv6Container';