import React, { useState } from 'react'
import {InnerData, determineColorNew} from './utils';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles
import { css, cx } from '@emotion/css';
import copy from 'copy-to-clipboard';

interface RegProp{
  data: {
    name: string;
    value: InnerData;
    className: string;
  }
}

type VisibleTooltips = { [key: string]: boolean };

const Reg: React.FC<RegProp> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const regContainer = css`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 510px;
`;

  return (
    <div className={regContainer}>

<Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
<Handle type="target" position={Position.Top} id="target-top-1" style={{opacity:0, left:415}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{opacity:0, top:100, left:40}}/>
      <Handle type="source" position={Position.Right} id="source-right-1" style={{opacity:0, top:100, right:40}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom-1" style={{opacity:0, left:415}}/>

        <div>

<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="182px" height="189px" viewBox="-0.5 -0.5 182 189">
  <defs/>
  <g>
    <g>
      <rect x="0" y="0" width="180" height="180" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeDasharray="8 8" pointerEvents="all"/></g><g>
        <path d="M 70 160 L 80 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
      </g>
      <g>
        <path d="M 90 180 L 80 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
        </g>
        <g>
          <path d="M 90 180 L 100 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
          </g>
          <g>
            <path d="M 112.5 180 L 137.03 117.56" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
        <path d="M 139.59 111.04 L 140.29 118.84 L 133.77 116.28 Z" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="all"/>
        </g>
        <g>
          <path d="M 50 100 L 70 100 L 70 150 L 30 150 L 30 120 Z" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="8 8" transform="translate(50,0)scale(-1,1)translate(-50,0)" pointerEvents="all"/>
          </g>
          <g>
            <rect x="50" y="110" width="50" height="50" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  pointerEvents="all"/>
          </g>
          <g>
            <path d="M 60 151.88 L 60 118.12" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
          <path d="M 60 158.88 L 56.5 151.88 L 63.5 151.88 Z" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="all"/>
      <path d="M 60 111.12 L 63.5 118.12 L 56.5 118.12 Z" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="all"/></g>
      <g>
        <path d="M 60 110 L 60 90" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
      </g>
      <g>
        <path d="M 60 180 L 60 150" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/></g><g>
        <path d="M 110 170 L 100 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="stroke"/>
      </g>
      <g>
        <path d="M 110 170 L 120 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
        </g>
        <g>
          <path d="M 130 170 L 120 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="stroke"/>
          </g>
          <g>
            <path d="M 130 170 L 140 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/></g><g><path d="M 150 170 L 140 130" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
            </g>
            <g>
              <path d="M 150 170 L 155 150" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="stroke"/>
        </g>
        <g>
          <path d="M 70 70 L 80 40" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/></g><g><path d="M 90 90 L 80 40" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
        </g>
        <g>
          <path d="M 90 90 L 100 40" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
      </g>
      <g><path d="M 112.5 90 L 137.03 27.56" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
      <path d="M 139.59 21.04 L 140.29 28.84 L 133.77 26.28 Z" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="all"/>
      </g>
      <g>
        <path d="M 50 10 L 70 10 L 70 60 L 30 60 L 30 30 Z"  fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" strokeDasharray="8 8" transform="translate(50,0)scale(-1,1)translate(-50,0)" pointerEvents="all"/>
        </g>
        <g>
          <rect x="50" y="20" width="50" height="50" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  pointerEvents="all"/>
          </g>
          <g>
            <path d="M 60 61.88 L 60 28.12" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
            <path d="M 60 68.88 L 56.5 61.88 L 63.5 61.88 Z" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="all"/>
        <path d="M 60 21.12 L 63.5 28.12 L 56.5 28.12 Z" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="all"/>
        </g>
        <g>
          <path d="M 60 20 L 60 0" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
        </g>
        <g>
          <path d="M 60 90 L 60 60" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/></g><g><path d="M 110 80 L 100 40" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/>
      </g>
      <g>
        <path d="M 110 80 L 120 40" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/></g><g><path d="M 130 80 L 120 40" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="stroke"/></g>
      <g>
        <path d="M 130 80 L 140 40" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4" strokeMiterlimit="10" pointerEvents="stroke"/></g><g><path d="M 150 80 L 140 40" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="stroke"/>
        </g>
        <g>
          <path d="M 150 80 L 155 60" fill="rgb(38,38,38)" stroke="#FFFFFF" strokeWidth="4"  strokeMiterlimit="10" pointerEvents="stroke"/>
          </g>
          </g>
          </svg>              
        </div>

        <div className={scheme.svgValueColCon}>
      {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData?.live}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
        
        </div>
    </div>
  );
}

export default Reg;
