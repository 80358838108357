import React from 'react';
import { BaseEdge, EdgeProps } from 'reactflow';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerStart,
  markerEnd,
  data,
}: EdgeProps) {

  const edgeColor = data && (data.enabled.telemetry ? data.enabled.telemetry.includes(data.name) : false) ? 'green' : 'white';

  const edgeStyle = {
    ...style,
    stroke: edgeColor,
  }


  let finaledge;
  let finaledge1;

  if (id === 'edge1' || id === 'edge5'|| id === 'edge6' || id === 'edge20' || id === 'edge23' || id === 'edge27' || id === 'edge28' || id === 'edge31' || id === 'edge32' ){
    // console.log(sourceX, sourceY, targetX, targetY)
    // console.log(edgePath);
    sourceY = sourceY - 10;
    targetX = targetX + 10;
    const edge = `M${sourceX}  ${sourceY}L ${targetX-5}, ${sourceY}Q ${targetX}, ${sourceY} ${targetX},${sourceY+5}L${targetX} ${targetY}`;
    sourceY = sourceY + 20;
    targetX = targetX - 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${targetX-5}, ${sourceY}Q ${targetX}, ${sourceY} ${targetX},${sourceY+5}L${targetX} ${targetY}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else if (id === 'edgeLV1FH4' || id === 'edge7' || id === 'edgeHeT2PT1' || id === 'edge24' || id === 'edge25' || id === 'edge26' || id === 'edge29' || id === 'edge30'){

    sourceY = sourceY - 10;
    targetX = targetX - 10;
    const edge = `M${sourceX}  ${sourceY}L ${targetX+5}, ${sourceY}Q ${targetX}, ${sourceY} ${targetX},${sourceY+5}L${targetX} ${targetY}`;
    sourceY = sourceY + 20;
    targetX = targetX + 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${targetX+5}, ${sourceY}Q ${targetX}, ${sourceY} ${targetX},${sourceY+5}L${targetX} ${targetY}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else if (id === 'edge12' || id === 'edgePT5PV5' || id === 'edge19' || id === 'edge36' || id === 'edge40'){

    sourceX = sourceX - 10;
    targetY = targetY - 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-5}Q ${sourceX}, ${targetY} ${sourceX-5},${targetY}L${targetX} ${targetY}`;
    sourceX = sourceX + 20;
    targetY = targetY + 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-5}Q ${sourceX}, ${targetY} ${sourceX-5},${targetY}L${targetX} ${targetY}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else if (id === 'edge13' || id === 'edgePT3PV5' || id === 'edge18'){
    sourceX = sourceX + 10;
    targetY = targetY - 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-5}Q ${sourceX}, ${targetY} ${sourceX+5},${targetY}L${targetX} ${targetY}`;
    sourceX = sourceX - 20;
    targetY = targetY + 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-5}Q ${sourceX}, ${targetY} ${sourceX+5},${targetY}L${targetX} ${targetY}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else if (id === 'edgeFPT1' || id === 'edgeRegF2'){

    sourceX = sourceX - 10;
    targetX = targetX - 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-205}Q ${sourceX}, ${targetY-200} ${sourceX-5},${targetY-200}L${targetX+5} ${targetY-200} Q ${targetX}, ${targetY-200} ${targetX},${targetY-195} L${targetX} ${targetY}`;
    sourceX = sourceX + 20;
    targetY = targetY + 20;
    targetX = targetX + 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-205}Q ${sourceX}, ${targetY-200} ${sourceX-5},${targetY-200}L${targetX+5} ${targetY-200} Q ${targetX}, ${targetY-200} ${targetX},${targetY-195} L${targetX} ${targetY-20}`;
    finaledge = edge;
    finaledge1 = edge1;
  }else if ( id === 'edge35'){

      sourceX = sourceX - 10;
      targetX = targetX - 10;
      const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-195}Q ${sourceX}, ${targetY-190} ${sourceX-5},${targetY-190}L${targetX+5} ${targetY-190} Q ${targetX}, ${targetY-190} ${targetX},${targetY-185} L${targetX} ${targetY}`;
      sourceX = sourceX + 20;
      targetY = targetY + 20;
      targetX = targetX + 20;
      const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-195}Q ${sourceX}, ${targetY-190} ${sourceX-5},${targetY-190}L${targetX+5} ${targetY-190} Q ${targetX}, ${targetY-190} ${targetX},${targetY-185} L${targetX} ${targetY-20}`;
      finaledge = edge;
      finaledge1 = edge1;
  }else if ( id === 'edge34' || id === 'edge38'){

    sourceY = sourceY - 10;
    targetX = targetX - 50;
    targetY = targetY + 50;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX-15}, ${sourceY}Q ${sourceX-20}, ${sourceY} ${sourceX-20},${sourceY+5} L${sourceX-20} ${targetY-5} Q ${sourceX-20}, ${targetY} ${sourceX-15},${targetY} L${targetX} ${targetY}`;
    sourceY = sourceY - 20;
    sourceX = sourceX - 20;
    targetY = targetY + 20;
    const edge1 = `M${sourceX+20}  ${sourceY}L ${sourceX-15}, ${sourceY}Q ${sourceX-20}, ${sourceY} ${sourceX-20},${sourceY+5} L${sourceX-20} ${targetY-5} Q ${sourceX-20}, ${targetY} ${sourceX-15},${targetY} L${targetX} ${targetY}`;
    finaledge = edge;
    finaledge1 = edge1;
}  else if (id === 'edgeHeRegLV1' ){
    sourceX = sourceX - 10;
    targetX = targetX - 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-55}Q ${sourceX}, ${targetY-50} ${sourceX-5},${targetY-50}L${targetX+5} ${targetY-50} Q ${targetX}, ${targetY-50} ${targetX},${targetY-45} L${targetX} ${targetY}`;
    sourceX = sourceX + 20;
    targetY = targetY + 20;
    targetX = targetX + 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-55}Q ${sourceX}, ${targetY-50} ${sourceX-5},${targetY-50}L${targetX+5} ${targetY-50} Q ${targetX}, ${targetY-50} ${targetX},${targetY-45} L${targetX} ${targetY-20}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  //   else if (id === 'edge35' || id === 'edge36'){

  //   sourceX = sourceX - 10;
  //   targetX = targetX - 10;
  //   const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-105}Q ${sourceX}, ${targetY-100} ${sourceX-5},${targetY-100}L${targetX+5} ${targetY-100} Q ${targetX}, ${targetY-100} ${targetX},${targetY-95} L${targetX} ${targetY}`;
  //   sourceX = sourceX + 20;
  //   targetY = targetY + 20;
  //   targetX = targetX + 20;
  //   const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-105}Q ${sourceX}, ${targetY-100} ${sourceX-5},${targetY-100}L${targetX+5} ${targetY-100} Q ${targetX}, ${targetY-100} ${targetX},${targetY-95} L${targetX} ${targetY-20}`;
  //   finaledge = edge;
  //   finaledge1 = edge1;
  // }
  else if (id === 'edgeRegF3'){
    sourceX = sourceX + 10;
    targetX = targetX + 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-205}Q ${sourceX}, ${targetY-200} ${sourceX+5},${targetY-200}L${targetX-5} ${targetY-200} Q ${targetX}, ${targetY-200} ${targetX},${targetY-195} L${targetX} ${targetY}`;
    sourceX = sourceX - 20;
    targetY = targetY + 20;
    targetX = targetX - 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-205}Q ${sourceX}, ${targetY-200} ${sourceX+5},${targetY-200}L${targetX-5} ${targetY-200} Q ${targetX}, ${targetY-200} ${targetX},${targetY-195} L${targetX} ${targetY-20}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else if (id === 'edge4'){
    sourceX = sourceX + 10;
    targetX = targetX + 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-55}Q ${sourceX}, ${targetY-50} ${sourceX+5},${targetY-50}L${targetX-5} ${targetY-50} Q ${targetX}, ${targetY-50} ${targetX},${targetY-45} L${targetX} ${targetY}`;
    sourceX = sourceX - 20;
    targetY = targetY + 20;
    targetX = targetX - 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-55}Q ${sourceX}, ${targetY-50} ${sourceX+5},${targetY-50}L${targetX-5} ${targetY-50} Q ${targetX}, ${targetY-50} ${targetX},${targetY-45} L${targetX} ${targetY-20}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else if (id === 'edge33'){
    sourceX = sourceX + 10;
    targetX = targetX + 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-165}Q ${sourceX}, ${targetY-160} ${sourceX+5},${targetY-160}L${targetX-5} ${targetY-160} Q ${targetX}, ${targetY-160} ${targetX},${targetY-155} L${targetX} ${targetY}`;
    sourceX = sourceX - 20;
    targetY = targetY + 20;
    targetX = targetX - 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-165}Q ${sourceX}, ${targetY-160} ${sourceX+5},${targetY-160}L${targetX-5} ${targetY-160} Q ${targetX}, ${targetY-160} ${targetX},${targetY-155} L${targetX} ${targetY-20}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else if (id === 'edge37'){
    sourceX = sourceX + 10;
    targetX = targetX + 10;
    const edge = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-105}Q ${sourceX}, ${targetY-100} ${sourceX+5},${targetY-100}L${targetX-5} ${targetY-100} Q ${targetX}, ${targetY-100} ${targetX},${targetY-95} L${targetX} ${targetY}`;
    sourceX = sourceX - 20;
    targetY = targetY + 20; 
    targetX = targetX - 20;
    const edge1 = `M${sourceX}  ${sourceY}L ${sourceX}, ${targetY-105}Q ${sourceX}, ${targetY-100} ${sourceX+5},${targetY-100}L${targetX-5} ${targetY-100} Q ${targetX}, ${targetY-100} ${targetX},${targetY-95} L${targetX} ${targetY-20}`;
    finaledge = edge;
    finaledge1 = edge1;
  }
  else{
    sourceX = sourceX - 10;
    targetX = targetX - 10;
    const edge = `M${sourceX}  ${sourceY}L${targetX} ${targetY}`;
    sourceX = sourceX + 20;
    targetX = targetX + 20;
    const edge1 = `M${sourceX}  ${sourceY}L${targetX} ${targetY}`;
    finaledge = edge;
    finaledge1 = edge1;
  }

  return (
    <>
      <BaseEdge path={finaledge} style={edgeStyle}/>
      <BaseEdge path={finaledge1} style={edgeStyle}/>
    </>
  );

}
