import React from 'react'
import { css } from '@emotion/css';

interface RCSProp{
  data:{
    name: string;
    className: string;
  }
}

const RCSA: React.FC<RCSProp> = ({data}) => {

  const rcsAContainer = css`
  width: 800px;
  height: 720px;
  border: 6px dashed rgb(147, 151, 159);
  text-align: text;
  display: flex;
  justify-content: flex-start;
  `;

  return (
    <div className={rcsAContainer}>
      <p>{data.name}</p>
    </div>
  )
}

export default RCSA;
