import React, { useState } from 'react'
import {InnerData, determineColorNew, TLM} from './utils';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import { css, cx } from '@emotion/css';
import * as scheme from './Scheme'; // Import your styles
import copy from 'copy-to-clipboard';

interface PMDTankProp{
  data: {
    name: string;
    value: InnerData;
    className: string;
    mass: {
      initial: TLM;
      remaining: TLM;
    }
  }
}

type VisibleTooltips = { [key: string]: boolean };

const PMDTank: React.FC<PMDTankProp> = ({data}) => {

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const calRamainingPerc = (initial: TLM, remaining: TLM) => {
    if (!initial || !remaining){
      return;
    }
    return parseFloat(remaining.telemetry)/parseFloat(initial.telemetry) * 100;
  }

  const remainingPerc = calRamainingPerc(data.mass?.initial, data.mass?.remaining);

  const fitlerRemain = remainingPerc ? remainingPerc : 30;

  const strokeWidth = 6;
  const svgSize = 170;
  const ellipseRadius = 80;
  const centerPoint = svgSize / 2;

  return (
    <div className={`${data.className}`}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

        <div>

        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="161px" height="161px" viewBox="-0.5 -0.5 171 171">
          <defs>
          <clipPath id="clipPath">
              <ellipse 
                cx={centerPoint} 
                cy={centerPoint} 
                rx={ellipseRadius - strokeWidth / 2} 
                ry={ellipseRadius - strokeWidth / 2} 
              />
            </clipPath>
          </defs>
          <g>
              {/* Background ellipse */}
              <ellipse 
              cx={centerPoint} 
              cy={centerPoint} 
              rx={ellipseRadius} 
              ry={ellipseRadius} 
              fill="rgb(38,38,38)" 
              stroke="#FFFFFF" 
              strokeWidth={strokeWidth} 
            />
            
            {/* Clipped fill area */}
            <g clipPath="url(#clipPath)">
              <rect 
                x="0" 
                y={svgSize - (svgSize * fitlerRemain / 100)} 
                width={svgSize} 
                height={svgSize * fitlerRemain / 100} 
                fill="green" 
              />
            </g>
            <g>
              <path d="M 8.64 108 Q 54 150 69 120 Q 84 90 99 120 Q 114 150 159.36 106.88" fill="none" stroke="#FFFFFF" strokeWidth="6" strokeMiterlimit="10" pointerEvents="stroke"/>
            </g>
            <g>
                <text x="40" y="70" fill="white" fontSize="35px">{data.name}</text>
            </g>
            <g>
            <text 
              x={centerPoint} 
              y="90" 
              fill="white" 
              fontSize="30px" 
              textAnchor="middle" 
              dominantBaseline="middle"
            >
              {fitlerRemain.toFixed(0)}%
            </text>            </g>
          </g>
        </svg>

              
        </div>

        <div className={scheme.svgValueColCon}>
      {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData?.live}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)] 
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
        
        </div>
    </div>
  );
}

export default PMDTank;
